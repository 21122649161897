import i18n from "i18next";
import { InitOptions } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import ar from "../translations/strings-ar-SA.puff.json";
import de from "../translations/strings-de-DE.puff.json";
import el from "../translations/strings-el-GR.puff.json";
import en from "../translations/strings-en-US.puff.json";
import esES from "../translations/strings-es-ES.puff.json";
import es419 from "../translations/strings-es-CO.puff.json";
import fr from "../translations/strings-fr-FR.puff.json";
import he from "../translations/strings-he-IL.puff.json";
import id from "../translations/strings-id-ID.puff.json";
import it from "../translations/strings-it-IT.puff.json";
import ja from "../translations/strings-ja-JP.puff.json";
import ko from "../translations/strings-ko-KR.puff.json";
import pl from "../translations/strings-pl-PL.puff.json";
import pt from "../translations/strings-pt-BR.puff.json";
import ru from "../translations/strings-ru-RU.puff.json";
import th from "../translations/strings-th-TH.puff.json";
import tr from "../translations/strings-tr-TR.puff.json";
import vi from "../translations/strings-vi-VN.puff.json";
import zhCN from "../translations/strings-zh-CN.puff.json";
import zhTW from "../translations/strings-zh-TW.puff.json";

import PUFFJ from "@amzn/katal-localization/dist/puffj/puffj";
const LANGUAGE = "en-US";

//function takes in puff.json content and returns a JSON formatted translation object
const convertPuffjToJson = (
  fileContents: Record<string, unknown>
): Record<string, Record<string, unknown>> => {
  const puffj = new PUFFJ({
    text: JSON.stringify(fileContents),
  });

  return {
    translation: Object.keys(puffj.toObject().resources).reduce(
      (obj, stringId) => ({
        ...obj,
        [stringId]: puffj.get(stringId, {}),
      }),
      {}
    ),
  };
};

export const getUserLanguage = () => {
  if (navigator.languages && navigator.languages.length > 0) {
    return navigator.languages[0];
  } else {
    return navigator.language || "en";
  }
};

export const resources = {
  "ar-SA": convertPuffjToJson(ar),
  ar: convertPuffjToJson(ar),
  "de-DE": convertPuffjToJson(de),
  de: convertPuffjToJson(de),
  "el-GR": convertPuffjToJson(el),
  el: convertPuffjToJson(el),
  "en-US": convertPuffjToJson(en),
  en: convertPuffjToJson(en),
  "es-ES": convertPuffjToJson(esES),
  "es-419": convertPuffjToJson(es419),
  "fr-FR": convertPuffjToJson(fr),
  fr: convertPuffjToJson(fr),
  "he-IL": convertPuffjToJson(he),
  he: convertPuffjToJson(he),
  "id-ID": convertPuffjToJson(id),
  id: convertPuffjToJson(id),
  "it-IT": convertPuffjToJson(it),
  it: convertPuffjToJson(it),
  "ja-JP": convertPuffjToJson(ja),
  ja: convertPuffjToJson(ja),
  "ko-KR": convertPuffjToJson(ko),
  ko: convertPuffjToJson(ko),
  "pl-PL": convertPuffjToJson(pl),
  pl: convertPuffjToJson(pl),
  "pt-BR": convertPuffjToJson(pt),
  pt: convertPuffjToJson(pt),
  "ru-RU": convertPuffjToJson(ru),
  ru: convertPuffjToJson(ru),
  "th-TH": convertPuffjToJson(th),
  th: convertPuffjToJson(th),
  "tr-TR": convertPuffjToJson(tr),
  tr: convertPuffjToJson(tr),
  "vi-VN": convertPuffjToJson(vi),
  vi: convertPuffjToJson(vi),
  "zh-CN": convertPuffjToJson(zhCN),
  "zh-TW": convertPuffjToJson(zhTW),
} as const;

const initOptions: InitOptions = {
  debug: false,
  fallbackLng: "en-US",
  lng: LANGUAGE,
  interpolation: {
    prefix: "{",
    suffix: "}",
    unescapePrefix: undefined, // all string interpolation inputs will be escaped to guard against XSS unless overridden on a per-translation basis
    format: (value, format, lng) => {
      if (format === "datetime") {
        return new Date(value).toLocaleDateString(lng, {
          day: "numeric",
          year: "numeric",
          month: "short",
        });
      }
      return value;
    },
  },
  react: {
    transKeepBasicHtmlNodesFor: ["br", "em", "p", "strong"],
  },
  resources,
  supportedLngs: [
    "ar-SA",
    "ar",
    "de-DE",
    "de",
    "el-GR",
    "el",
    "en-US",
    "en",
    "es-CO",
    "es-ES",
    "es-419",
    "fr-FR",
    "fr",
    "he-IL",
    "he",
    "id-ID",
    "id",
    "it-IT",
    "it",
    "ja-JP",
    "ja",
    "ko-KR",
    "ko",
    "pl-PL",
    "pl",
    "pt-BR",
    "pt",
    "ru-RU",
    "ru",
    "th-TH",
    "th",
    "tr-TR",
    "tr",
    "vi-VN",
    "vi",
    "zh-CN",
    "zh-TW",
  ],
};

i18n.use(LanguageDetector).use(initReactI18next).init(initOptions);

export default i18n;

import { useLocation, useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Box from "@amzn/awsui-components-react/polaris/box";
import Flashbar, {
  FlashbarProps,
} from "@amzn/awsui-components-react/polaris/flashbar";
import CheckoutSessionDetails from "../components/CheckoutSessionDetails";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import { FRONTEND_SELECTORS } from "../common/constants";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { usePreventAuthRedirectParam } from "../hooks/usePreventAuthRedirectParam";
import i18n from "../../i18n/config";
import Button from "@amzn/awsui-components-react/polaris/button";
import { Link } from "@amzn/awsui-components-react";
import { Trans, useTranslation } from "react-i18next";
import { AWS_BILLING_CONSOLE } from "../common/constants";

export enum FlashbarError {
  NOT_ROOT_USER = "notRootUser",
  ERROR_SAVING_ACCOUNT = "errorSavingAccount",
  ERROR_FINALIZING_SESSION = "errorFinalizingSession",
  ERROR_INVALID_PAYMENT_REGION = "invalidPaymentRegion",
  ERROR_INVALID_MARKETPLACE_GROUP = "invalidMarketplaceGroup",
  ERROR_UNAUTHORIZED_PAYMENT = "unauthorizedPayment",
  NOT_APPLICABLE_FOR_FREE_TRIAL = "notApplicableForFreeTrial",
  ALREADY_HAS_PENDING_PAYMENTS = "alreadyHasPendingPayments",
}

const StyledFlashbar = styled(Flashbar)`
  padding-top: 8px;
`;

const useCheckoutSessionMain = () => {
  const preventAuthRedirectParam = usePreventAuthRedirectParam();
  useAuth(preventAuthRedirectParam);
  const { sessionId } = useParams();
  const location = useLocation();
  const [flashbarError, setFlashbarError] = useState<FlashbarError | undefined>(
    location?.state?.flashbarError
  );

  const [flashbarItems, setFlashbarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  useEffect(() => {
    if (flashbarError) {
      // Replace the old error when new flashbarError comes in via state
      if (
        location?.state?.flashbarError !== undefined &&
        location?.state?.flashbarError !== flashbarError
      ) {
        setFlashbarError(location?.state?.flashbarError);
      }
      setFlashbarItems([
        {
          content: (
            <Trans
              i18nKey={`notification_flashbar_error_${flashbarError}`}
              components={[
                flashbarError === FlashbarError.ALREADY_HAS_PENDING_PAYMENTS ? (
                  <Link
                    href={AWS_BILLING_CONSOLE}
                    color="inverted"
                    variant="primary"
                  />
                ) : (
                  <></>
                ),
              ]}
            />
          ),
          action:
            flashbarError === FlashbarError.NOT_APPLICABLE_FOR_FREE_TRIAL ? (
              <Button
                ariaLabel={i18n.t(
                  "notification_flashbar_button_text_aria_label"
                )}
                href="https://support.aws.amazon.com/#/contacts/aws-training"
                iconAlign="right"
                iconName="external"
                target="_blank"
              >
                {" "}
                {
                  i18n.t("notification_flashbar_button_text") as React.ReactNode
                }{" "}
              </Button>
            ) : (
              <></>
            ),
          dismissible: true,
          dismissLabel: i18n.t("notification_flashbar_dismissLabel"),
          statusIconAriaLabel: i18n.t(
            "notification_flashbar_error_statusIconLabel"
          ),
          type: "error",
          ariaRole: "alert",
          onDismiss: () => setFlashbarError(undefined),
        },
      ]);
    } else if (flashbarItems.length > 0) {
      setFlashbarItems([]);
    }
  }, [flashbarError, flashbarItems.length, i18n]);

  return { sessionId, flashbarItems, setFlashbarError };
};

const CheckoutSessionMain = (): JSX.Element => {
  const { sessionId, flashbarItems, setFlashbarError } =
    useCheckoutSessionMain();

  return (
    <AppLayout
      content={
        <ContentLayout>
          <Box padding="xxxl">
            <CheckoutSessionDetails
              sessionId={sessionId}
              setFlashbarError={setFlashbarError}
            />
          </Box>
        </ContentLayout>
      }
      navigationHide
      toolsHide
      disableContentPaddings
      notifications={
        flashbarItems.length > 0 && (
          <StyledFlashbar
            data-testId="pre_checkout_flash_bar"
            items={flashbarItems}
          />
        )
      }
      headerSelector={FRONTEND_SELECTORS.HEADER}
      footerSelector={FRONTEND_SELECTORS.FOOTER}
    />
  );
};

export default CheckoutSessionMain;

import Box from "@amzn/awsui-components-react/polaris/box";
import Container from "@amzn/awsui-components-react/polaris/container";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const HeaderContainer = styled(Container)`
  position: sticky !important;
  top: 0;
  width: 100%;
  z-index: 1002;
`;

// Centers logo vertically (otherwise, small amount of space below)
const LogoBox = styled(Box)`
  display: flex;
  align-items: center;
`;

const CheckoutHeader = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <HeaderContainer id="h">
      <LogoBox padding={{ vertical: "xs" }}>
        <img
          src="/public/aws-tc-logo.svg"
          alt={t("header_awsTCLogo_alt")}
          data-testid="Header_Logo"
        />
      </LogoBox>
    </HeaderContainer>
  );
};

export default CheckoutHeader;

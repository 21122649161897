import { gql } from "@apollo/client";

export const CREATE_PAYMENT_METHOD = gql`
  mutation CreatePaymentMethod($input: CreateAWSAccountPaymentMethodInput!) {
    paymentMethod: createAWSAccountPaymentMethod(input: $input) {
      id
    }
  }
`;

export const ADD_PAYMENT_METHOD_TO_CHECKOUT_SESSION = gql`
  mutation addPaymentMethodToCheckoutSession(
    $input: AddPaymentMethodToCheckoutSessionInput!
  ) {
    checkoutSession: addPaymentMethodToCheckoutSession(input: $input) {
      id
    }
  }
`;

export const FINALIZE_SESSION = gql`
  mutation FinalizeCheckoutSession($input: FinalizeCheckoutSessionInput!) {
    checkoutSession: finalizeCheckoutSession(input: $input) {
      id
    }
  }
`;

export const CREATE_SB_DIGITAL_CHECKOUT_SESSION = gql`
  mutation createNewSbDigitalOrderCheckoutSession(
    $input: CreateNewSbDigitalOrderCheckoutSessionInput!
  ) {
    createNewSbDigitalOrderCheckoutSession(input: $input)
  }
`;

import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Container from "@amzn/awsui-components-react/polaris/container";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Box from "@amzn/awsui-components-react/polaris/box";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import styled from "styled-components";
import { CheckoutSession, CheckoutSessionType } from "../common/types";
import Term from "./Term";
import { useFinalizeCheckoutSession } from "../hooks/useFinalizeCheckoutSession";
import { Fragment } from "react";
import { createCheckoutEventTrigger } from "../analytics/createCheckoutEventTrigger";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import i18n from "../../i18n/config";
import LoadingContent from "./LoadingContent";

const SubmitButton = styled(Button)`
  width: 100%;
  text-align: center !important;
`;

interface FinalizeCheckoutSessionProps {
  checkoutSession: CheckoutSession;
  isFreeTrial: boolean;
  isPromotional: boolean;
  appliedBenefitIds: string[] | undefined;
  freeTrialEndsDate: string | undefined;
  freeTrialDuration: number | undefined;
  promotionalDiscount: number | undefined;
  promotionalEndsDate: string | undefined;
  finalizeSession: () => void;
  onClickContinueToAws: () => void;
}

const FinalizeCheckoutSession = ({
  checkoutSession,
  isFreeTrial,
  isPromotional,
  appliedBenefitIds,
  freeTrialEndsDate,
  freeTrialDuration,
  promotionalDiscount,
  promotionalEndsDate,
  onClickContinueToAws,
  finalizeSession,
}: FinalizeCheckoutSessionProps): JSX.Element => {
  const { t } = useTranslation();
  const dir = i18n.dir();
  const {
    shouldShowContinueToAws,
    finalizeCheckoutSessionComponent,
    displayPeriodIntlKey,
    estimatedTotalPeriodAmount,
    previousEstimatedTotalPeriodAmount,
    subtotal,
    pricePerUnit,
    quantity,
    applyBenefitToOfferLoading,
  } = useFinalizeCheckoutSession(
    checkoutSession,
    appliedBenefitIds,
    isPromotional
  );
  const rtlClass = dir === "rtl" ? "rtl-header" : "";
  const labelAlignment = dir === "rtl" ? "right" : "left";
  const modality = checkoutSession?.items[0]?.offer?.modality;
  let triggerCheckoutEvent: any;

  if (applyBenefitToOfferLoading) {
    return <LoadingContent text={t("loadingPage_loadingText_loading")} />;
  }

  return (
    <SpaceBetween size="m">
      <Container data-testid="finalize_checkout_container">
        <SpaceBetween size="m">
          <Header className={rtlClass} variant="h2">
            {t("preCheckout_finalizeCheckout_summary")}
          </Header>

          {checkoutSession.type === CheckoutSessionType.NEW_ORDER ? (
            <>
              {modality === "AWS_JAM" ? (
                <>
                  <Grid
                    data-testid="subtotal_wrapper"
                    gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
                  >
                    <Box
                      data-testid="preCheckout_price_per_participant_key"
                      textAlign="left"
                      fontSize="heading-m"
                      padding={{ vertical: "xxs" }}
                    >
                      {t("postCheckout_orderSummary_pricePerParticipant")}
                    </Box>
                    <Box
                      data-testid="preCheckout_price_per_participant_value"
                      textAlign="right"
                      fontSize="heading-m"
                      padding={{ vertical: "xxs" }}
                    >
                      {t("preCheckout_finalizeCheckout_subtotal_pricePerUnit", {
                        pricePerUnit,
                      })}
                      <Box
                        variant="span"
                        color="text-body-secondary"
                        fontSize="heading-s"
                      >
                        {t("preCheckout_finalizeCheckout_subtotal_quantity", {
                          quantity,
                        })}
                      </Box>
                    </Box>
                  </Grid>
                </>
              ) : (
                <></>
              )}
              {isFreeTrial && (
                <Grid
                  className={rtlClass}
                  data-testid="pre_checkout_free_trial_days_wrapper"
                  gridDefinition={[
                    {
                      colspan: 6,
                    },
                    {
                      colspan: 6,
                    },
                  ]}
                >
                  <Box
                    textAlign={labelAlignment}
                    fontSize="heading-m"
                    padding={{ vertical: "xxs" }}
                  >
                    {t(
                      "preCheckout_finalizeCheckout_discount_free_trial_label"
                    )}
                  </Box>
                  <Box
                    textAlign="right"
                    fontSize="heading-m"
                    padding={{ vertical: "xxs" }}
                  >
                    {t(
                      "preCheckout_finalizeCheckout_discount_freeTrial_duration",
                      {
                        freeTrialDuration: Number.isInteger(freeTrialDuration)
                          ? freeTrialDuration
                          : (freeTrialDuration as number).toFixed(2),
                      }
                    )}
                  </Box>
                </Grid>
              )}
              <Grid
                className={rtlClass}
                data-testid="subtotal_wrapper"
                gridDefinition={[
                  {
                    colspan: 6,
                  },
                  {
                    colspan: 6,
                  },
                ]}
              >
                <Box
                  textAlign={labelAlignment}
                  fontSize="heading-m"
                  padding={{ vertical: "xxs" }}
                >
                  {t("preCheckout_finalizeCheckout_subtotal_label")}
                </Box>
                <Box
                  textAlign="right"
                  fontSize="heading-m"
                  padding={{ vertical: "xxs" }}
                >
                  {t("preCheckout_finalizeCheckout_subtotal_installmentPlan", {
                    subtotal,
                  })}
                </Box>
              </Grid>
              {isPromotional && (
                <Grid
                  className={rtlClass}
                  data-testid="pre_checkout_promotional_discount_wrapper"
                  gridDefinition={[
                    {
                      colspan: 6,
                    },
                    {
                      colspan: 6,
                    },
                  ]}
                >
                  <Box
                    textAlign={labelAlignment}
                    fontSize="heading-m"
                    padding={{ vertical: "xxs" }}
                  >
                    {t(
                      "preCheckout_finalizeCheckout_promotional_discount_label"
                    )}
                  </Box>
                  <Box
                    textAlign="right"
                    fontSize="heading-m"
                    padding={{ vertical: "xxs" }}
                  >
                    {t(
                      "preCheckout_finalizeCheckout_promotional_discount_value",
                      {
                        promotionalDiscount: promotionalDiscount,
                      }
                    )}
                  </Box>
                </Grid>
              )}
              <Grid
                className={rtlClass}
                data-testid="estimated_total_wrapper"
                gridDefinition={[
                  {
                    colspan: 6,
                  },
                  {
                    colspan: 6,
                  },
                ]}
              >
                <Box
                  textAlign={labelAlignment}
                  data-testid="preCheckout_estimated_payments_key"
                  fontSize="heading-m"
                  padding={{ vertical: "xxs" }}
                  fontWeight={modality === "DIGITAL" ? undefined : "bold"}
                >
                  {modality === "DIGITAL"
                    ? t(
                        "preCheckout_finalizeCheckout_estimatedTotal_digital_label"
                      )
                    : t("preCheckout_finalizeCheckout_estimatedTotal_label")}
                </Box>
                <Box
                  textAlign="right"
                  data-testid="preCheckout_estimated_payments_value"
                  fontSize="heading-m"
                  padding={{ vertical: "xxs" }}
                  fontWeight={modality === "DIGITAL" ? undefined : "bold"}
                >
                  {modality === "DIGITAL"
                    ? t(
                        "preCheckout_finalizeCheckout_estimatedTotal_digital_installmentPlan",
                        {
                          periodAmount: estimatedTotalPeriodAmount,
                        }
                      )
                    : t(
                        "preCheckout_finalizeCheckout_estimatedTotal_installmentPlan",
                        {
                          displayPeriod: t(displayPeriodIntlKey),
                          periodAmount: estimatedTotalPeriodAmount,
                        }
                      )}
                  *
                </Box>
              </Grid>
              {isPromotional && !isFreeTrial && (
                <Alert
                  data-testid="pre_checkout_finalize_alert"
                  statusIconAriaLabel="Info"
                >
                  <Box
                    variant="span"
                    color="text-body-secondary"
                    data-testid="pre_checkout_finalize_alert_promotional"
                  >
                    <div dir={dir}>
                      {t(
                        "preCheckout_finalizeCheckout_nonfreeTrial_promotional_alert",
                        {
                          promotionalEndsDate: promotionalEndsDate,
                        }
                      )}
                    </div>
                  </Box>
                </Alert>
              )}
              {isFreeTrial && (
                <>
                  <Grid
                    className={rtlClass}
                    data-testid="pre_checkout_free_trial_estimated_total_wrapper"
                    gridDefinition={[
                      {
                        colspan: 6,
                      },
                      {
                        colspan: 6,
                      },
                    ]}
                  >
                    <Box
                      textAlign={labelAlignment}
                      data-testid="pre_checkout_free_trial_estimated_total_key"
                      fontSize="heading-m"
                      fontWeight="bold"
                      padding={{ vertical: "xxs" }}
                    >
                      {t(
                        "preCheckout_finalizeCheckout_freeTrial_estimatedTotal_label"
                      )}
                    </Box>
                    <Box
                      textAlign="right"
                      data-testid="pre_checkout_free_trial_estimated_total_value"
                      fontSize="heading-m"
                      fontWeight="bold"
                      padding={{ vertical: "xxs" }}
                    >
                      {t(
                        "preCheckout_finalizeCheckout_freeTrial_estimatedTotal_value"
                      )}
                    </Box>
                  </Grid>
                  <Alert
                    data-testid="pre_checkout_finalize_alert"
                    statusIconAriaLabel="Info"
                  >
                    <Box variant="span" color="text-body-secondary">
                      {isPromotional ? (
                        <div
                          data-testid="pre_checkout_finalize_alert_free_trial_and_promotional"
                          dir={dir}
                        >
                          {t(
                            "preCheckout_finalizeCheckout_freeTrial_promotional_alert",
                            {
                              freeTrialEndDate: freeTrialEndsDate,
                              promotionalEndsDate: promotionalEndsDate,
                            }
                          )}
                        </div>
                      ) : (
                        <div
                          data-testid="pre_checkout_finalize_alert_free_trial"
                          dir={dir}
                        >
                          {t("preCheckout_finalizeCheckout_freeTrial_endDate", {
                            freeTrialEndDate: freeTrialEndsDate,
                          })}
                        </div>
                      )}
                    </Box>
                  </Alert>
                </>
              )}
            </>
          ) : checkoutSession.type ===
            CheckoutSessionType.REPLACE_ORDER_ITEM ? (
            <>
              <Grid
                className={rtlClass}
                data-testid="previous_estimated_total_wrapper"
                gridDefinition={[
                  {
                    colspan: 6,
                  },
                  {
                    colspan: 6,
                  },
                ]}
              >
                <Box
                  textAlign="left"
                  fontSize="heading-m"
                  padding={{ vertical: "xxs" }}
                >
                  {t(
                    "preCheckout_finalizeCheckout_previousEstimatedTotal_label"
                  )}
                </Box>
                <Box
                  textAlign="right"
                  fontSize="heading-m"
                  padding={{ vertical: "xxs" }}
                >
                  {t(
                    "preCheckout_finalizeCheckout_estimatedTotal_installmentPlan",
                    {
                      displayPeriod: t(displayPeriodIntlKey),
                      periodAmount: previousEstimatedTotalPeriodAmount,
                    }
                  )}
                </Box>
              </Grid>
              <Grid
                className={rtlClass}
                data-testid="new_estimated_total_wrapper"
                gridDefinition={[
                  {
                    colspan: 6,
                  },
                  {
                    colspan: 6,
                  },
                ]}
              >
                <Box
                  textAlign="left"
                  data-testid="preCheckout_new_estimated_total_key"
                  fontSize="heading-m"
                  fontWeight="bold"
                  padding={{ vertical: "xxs" }}
                >
                  {t("preCheckout_finalizeCheckout_newEstimatedTotal_label")}
                </Box>
                <Box
                  textAlign="right"
                  data-testid="preCheckout_new_estimated_total_value"
                  fontSize="heading-m"
                  fontWeight="bold"
                  padding={{ vertical: "xxs" }}
                >
                  {t(
                    "preCheckout_finalizeCheckout_estimatedTotal_installmentPlan",
                    {
                      displayPeriod: t(displayPeriodIntlKey),
                      periodAmount: estimatedTotalPeriodAmount,
                    }
                  )}
                  *
                </Box>
              </Grid>
            </>
          ) : (
            <Fragment />
          )}

          {shouldShowContinueToAws ? (
            <>
              <SubmitButton
                variant="primary"
                formAction="none"
                onClick={() => onClickContinueToAws()}
                data-testid="finalize_checkout_continue_to_aws"
              >
                <div dir={dir}>
                  {t(
                    "preCheckout_finalizeCheckout_awsAccount_continue_button",
                    "Continue to AWS"
                  )}
                </div>
              </SubmitButton>
              <Box
                textAlign={labelAlignment}
                variant="span"
                color="text-body-secondary"
              >
                <div dir={dir}>
                  {t(
                    "preCheckout_finalizeCheckout_awsAccount_continue_description"
                  )}
                </div>
              </Box>
            </>
          ) : (
            <>
              {finalizeCheckoutSessionComponent.terms.map((term, idx) => {
                return <Term key={idx} term={term} />;
              })}
              <SubmitButton
                data-testid="finalize_checkout_submit"
                variant="primary"
                formAction="none"
                onClick={() => {
                  finalizeSession();
                  if (modality === "DIGITAL") {
                    const subscriptionPeriod = displayPeriodIntlKey.includes(
                      "month"
                    )
                      ? "Monthly"
                      : "Yearly";
                    triggerCheckoutEvent = createCheckoutEventTrigger(
                      checkoutSession,
                      "toolComplete",
                      "Purchase",
                      "B2I",
                      subscriptionPeriod
                    );
                  }
                  if (modality === "AWS_JAM") {
                    triggerCheckoutEvent = createCheckoutEventTrigger(
                      checkoutSession,
                      "toolComplete",
                      "Confirm_purchase",
                      "Event_checkout"
                    );
                  }
                  if (typeof triggerCheckoutEvent === "function")
                    triggerCheckoutEvent();
                }}
              >
                <div dir={dir}>
                  {finalizeCheckoutSessionComponent.submitText}
                </div>
              </SubmitButton>
            </>
          )}
        </SpaceBetween>
      </Container>
      <Box
        textAlign={labelAlignment}
        variant="span"
        color="text-body-secondary"
      >
        <div dir={dir}>{t("preCheckout_finalizeCheckout_finalTaxes_note")}</div>
      </Box>
    </SpaceBetween>
  );
};

export default FinalizeCheckoutSession;

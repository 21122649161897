import { useAmplifySubscription } from "../../hooks/useAmplifySubscription";

/*
  Borrowed from AwsTcPlatformFrontend, at least until
  the platform Auth Gateway is set up which will provide a
  common auth solution using cookies
 */

function WithAmplifySubscription(Component: (props: any) => JSX.Element) {
  return function InnerComponent(props: any) {
    useAmplifySubscription();
    return <Component {...props} />;
  };
}

export default WithAmplifySubscription;

import { useEffect } from "react";
import { TEMP_CREDS_SET_EVENT } from "../common/constants";

export const useOnTempCredsUpdated = (listener: () => void) => {
  useEffect(() => {
    window.addEventListener(TEMP_CREDS_SET_EVENT, listener);

    return () => {
      window.removeEventListener(TEMP_CREDS_SET_EVENT, listener);
    };
  }, []);
};

import { useTranslation } from "react-i18next";
import LoadingContent from "../components/LoadingContent";
import { useHandleAwsSignIn } from "../hooks/useHandleAwsSignIn";

const HandleAwsSignIn = (): JSX.Element => {
  const { t } = useTranslation();
  useHandleAwsSignIn();

  return <LoadingContent text={t("loadingPage_loadingText_loading")} />;
};

export default HandleAwsSignIn;

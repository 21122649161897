import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import {
  BILLING_DOMAIN,
  DOCEBO_DOMAIN,
  DOCEBO_ROUTE_CATALOG,
  FRONTEND_SELECTORS,
  SUBSCRIPTIONS,
} from "../common/constants";
import { useTranslation, Trans } from "react-i18next";
import { Box, Button, Link, SpaceBetween } from "@amzn/awsui-components-react";

import styled from "styled-components";
import { getNodeEnvironment } from "../common/nodeEnvironment";

const MaxWidthSmallScreen = "300px";
const MaxWidthLargeScreen = "400px";

const MiddleBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 576px) {
    max-width: ${MaxWidthSmallScreen};
  }
  @media (min-width: 576px) {
    max-width: ${MaxWidthLargeScreen};
  }
`;

const AlreadySubscribed = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <AppLayout
      content={
        <MiddleBox data-testid="already_subscribed_content">
          <SpaceBetween size="xs">
            <Box variant="h1" textAlign="center">
              <img
                src="/public/already-subscribed.svg"
                alt={t("alreadySubscribed_alt_text")}
                data-testid="Already_Subscribed_Logo"
              />
            </Box>
            <Box variant="h1" textAlign="center" color="text-body-secondary">
              {t(`already_subscribed_header_message`)}
            </Box>
            <Box variant="p" textAlign="center">
              <Trans
                i18nKey="already_subscribed_main_message"
                components={[
                  <Link
                    data-testid="manage_subscriptions_link"
                    key="manage_subscriptions_link"
                    href={BILLING_DOMAIN[getNodeEnvironment()] + SUBSCRIPTIONS}
                  />,
                ]}
              />
            </Box>
            <Box variant="p" textAlign="center">
              <Button
                variant="primary"
                href={
                  DOCEBO_DOMAIN[getNodeEnvironment()] + DOCEBO_ROUTE_CATALOG
                }
                iconAlign="right"
                iconName="external"
              >
                {t(`already_subscribed_course_catalog_button`)}
              </Button>
            </Box>
          </SpaceBetween>
        </MiddleBox>
      }
      contentType="default"
      navigationHide={true}
      toolsHide={true}
      headerSelector={FRONTEND_SELECTORS.HEADER}
      footerSelector={FRONTEND_SELECTORS.FOOTER}
      ariaLabels={{}}
    />
  );
};

export default AlreadySubscribed;

import { CheckoutSession } from "./../common/types";
import { APPLY_BENEFIT_TO_OFFER } from "../common/api/queries";
import { formatCurrency } from "../common/utilityFunctions";
import { useQuery } from "@apollo/client";
import moment from "moment";

export const DISPLAY_KEY_BY_BILLING_PERIOD: Record<string, string> = {
  MONTHLY: "postCheckout_orderSummary_total_value_period_month",
  MONTHLY_LEASE: "postCheckout_orderSummary_total_value_period_month",
  ANNUAL: "postCheckout_orderSummary_total_value_period_year",
  ONE_TIME: "postCheckout_orderSummary_total_value_period_oneTime",
};

const useApplyBenefitToOfferQuery = (
  offerId: string,
  appliedBenefitIds?: string[]
) =>
  useQuery(APPLY_BENEFIT_TO_OFFER, {
    variables: { id: offerId, appliedBeneifts: appliedBenefitIds },
    skip: offerId === undefined,
  });

export const usePostCheckoutOrderSummary = (
  checkoutSession: CheckoutSession,
  appliedBenefitIds?: string[],
  isPromotional?: boolean
) => {
  const item = checkoutSession.items[0];
  const offer = item.offer;
  const quantity = item.quantity ?? 1;

  if (!offer) {
    throw new Error("Session should have offer");
  }

  const { id: offerId, modality: offerModality } = offer;

  const { data: applyBenefitToOfferData, loading: applyBenefitToOfferLoading } =
    useApplyBenefitToOfferQuery(offerId, appliedBenefitIds);

  let offerPrice;
  let discountedPrice;
  let purchasingPlan;
  let paymentConfiguration;

  if (applyBenefitToOfferData) {
    const applyBenefitToOfferQueryResult = applyBenefitToOfferData.offerById;
    offerPrice = applyBenefitToOfferQueryResult.offerPrice;
    discountedPrice = applyBenefitToOfferQueryResult.discountedPrice;
    purchasingPlan = applyBenefitToOfferQueryResult.purchasingPlan;
    paymentConfiguration = applyBenefitToOfferQueryResult.paymentConfiguration;
  }

  const subtotal = formatCurrency(offerPrice * quantity);
  const offsetInHours =
    paymentConfiguration?.paymentScheduleConfiguration.offsetInHours;

  let pricePerUnit;
  let displayPeriodIntlKey;
  let formattedBillingDate;

  if (purchasingPlan) {
    pricePerUnit = purchasingPlan.price;
    displayPeriodIntlKey =
      DISPLAY_KEY_BY_BILLING_PERIOD[purchasingPlan.billingPeriod];

    const firstInstallmentDate =
      purchasingPlan.scheduledInstallments[0].installmentAttemptDates[0];
    formattedBillingDate = moment(firstInstallmentDate).format("MMMM D, YYYY");
  } else if (paymentConfiguration) {
    pricePerUnit = isPromotional ? discountedPrice : offerPrice;
    displayPeriodIntlKey =
      DISPLAY_KEY_BY_BILLING_PERIOD[paymentConfiguration.offerBillingPeriod];

    const orderCreationDateUTC = moment.utc(
      checkoutSession.createdOrder?.auditMetadata.createdAt
    );
    const nextBillingDate: Record<string, string | undefined> = {
      MONTHLY: moment(orderCreationDateUTC)
        .add(offsetInHours, "h")
        .add(1, "M")
        .startOf("month")
        .local()
        .format("MMMM D, YYYY"),
      MONTHLY_LEASE: moment(orderCreationDateUTC)
        .add(offsetInHours, "h")
        .add(1, "M")
        .local()
        .format("MMMM D, YYYY"),
      ANNUAL: moment(orderCreationDateUTC)
        .add(offsetInHours, "h")
        .add(1, "y")
        .local()
        .format("MMMM D, YYYY"),
      ONE_TIME: "Pay upfront",
    };
    formattedBillingDate =
      nextBillingDate[paymentConfiguration.offerBillingPeriod];
  }
  const totalBeforeTaxes = formatCurrency(quantity * pricePerUnit);
  const paymentMethod = checkoutSession.createdOrder?.paymentMethod.type;

  return {
    applyBenefitToOfferLoading,
    subtotal,
    paymentMethod,
    formattedBillingDate,
    totalBeforeTaxes,
    displayPeriodIntlKey,
    offerModality,
  };
};

import { Amplify, MemoryStorage } from "@aws-amplify/core";
import { useEffect } from "react";
import { authConfiguration } from "../auth/authConfiguration";
import { NodeEnvironment, getNodeEnvironment } from "../common/nodeEnvironment";
import Auth, { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

/*
  Borrowed from AwsTcPlatformFrontend, at least until
  the platform Auth Gateway is set up which will provide a
  common auth solution using cookies
 */

// Enable Amplify debugging for better insights when doing local development.
if (
  [
    NodeEnvironment.TEST,
    NodeEnvironment.DEVELOPMENT,
    NodeEnvironment.BETA,
    NodeEnvironment.GAMMA,
  ].includes(getNodeEnvironment())
) {
  (window as any).LOG_LEVEL = "DEBUG";
}

function addOrReplaceParams(url: string, newParams: Record<string, string>) {
  const urlObj = new URL(url);
  Object.entries(newParams).forEach(([key, value]) => {
    urlObj.searchParams.set(key, value);
  });
  return urlObj.toString();
}

// Creates a urlOpener function to intercept sign in calls and change the url.
// See docs, https://aws-amplify.github.io/amplify-js/api/interfaces/awscognitooauthopts.html
const createUrlInterceptor =
  (additionalParameters: Record<string, string> = {}) =>
  (url: string) => {
    const self = "_self";
    const { domain, signInUIDomain } = authConfiguration.oauth;
    const loginRequestUrl = `https://${domain}/oauth2/authorize`;
    let windowProxy;

    if (signInUIDomain && url.startsWith(loginRequestUrl)) {
      const signInUrl = url.replace(
        loginRequestUrl,
        `https://${signInUIDomain}/login`
      );
      windowProxy = window.open(
        addOrReplaceParams(signInUrl, additionalParameters),
        self
      );
    } else {
      windowProxy = window.open(url, self);
    }
    return windowProxy ? Promise.resolve(windowProxy) : Promise.reject();
  };

const configureAmplify = (
  additionalParameters?: Record<string, string>
): void => {
  Amplify.configure({
    Auth: {
      // The use of MemoryStorage is crucial here.
      // By default Amplify stores Gandalf tokens
      // in LocalStorage, which will result in an AppSec finding
      // if your team does not configure Amplify otherwise.
      storage: MemoryStorage,
      region: authConfiguration.region,
      userPoolId: authConfiguration.userPoolId,
      userPoolWebClientId: authConfiguration.userPoolWebClientId,
      oauth: {
        domain: authConfiguration.oauth.domain,
        scope: authConfiguration.oauth.scope,
        responseType: authConfiguration.oauth.responseType,
        redirectSignIn: authConfiguration.oauth.redirectSignIn,
        redirectSignOut: authConfiguration.oauth.redirectSignOut,
        ...{
          urlOpener: createUrlInterceptor(additionalParameters),
        },
      },
    },
  });
};

export const handleEmailVerification = async (identityProvider: string) => {
  configureAmplify({
    require_email_verification: "true",
    identity_provider: identityProvider,
  });
  return Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Cognito,
    customState: JSON.stringify({
      pathname: location.pathname,
      search: location.search,
    }),
  });
};

export const useAmplifyConfiguration = () => {
  useEffect(() => {
    configureAmplify();
  }, []);
};

import { useCheckoutSessionDetails } from "../hooks/useCheckoutSessionDetails";
import LoadingContent from "./LoadingContent";
import ErrorContent from "./ErrorContent";
import PreCheckoutDetails from "./PreCheckoutDetails";
import PostCheckoutDetails from "./PostCheckoutDetails";
import { CheckoutSessionStatus } from "../common/types";
import { showError } from "../common/utilityFunctions";
import { FlashbarError } from "../pages/CheckoutSessionMain";
import { NetworkStatus } from "@apollo/client";
import { useTranslation } from "react-i18next";

interface CheckoutSessionDetailsProps {
  sessionId?: string;
  setFlashbarError: (flashbarError?: FlashbarError) => void;
}

const CheckoutSessionDetails = ({
  sessionId,
  setFlashbarError,
}: CheckoutSessionDetailsProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    checkoutSession,
    checkoutSessionLoading,
    checkoutSessionError,
    checkoutSessionNetworkStatus,
    isFreeTrial,
    isPromotional,
    freeTrialEndsDate,
    freeTrialDuration,
    appliedBenefitIds,
    promotionalDiscount,
    promotionalEndsDate,
  } = useCheckoutSessionDetails({ sessionId });

  if (
    showError(checkoutSessionError) ||
    checkoutSession?.status === CheckoutSessionStatus.EXPIRED
  ) {
    return <ErrorContent errorType="badRequest" />;
  }

  if (
    !checkoutSession ||
    checkoutSessionLoading ||
    checkoutSessionNetworkStatus === NetworkStatus.refetch
  ) {
    return <LoadingContent text={t("loadingPage_loadingText_loading")} />;
  }

  if (checkoutSession.status === CheckoutSessionStatus.COMPLETE) {
    return (
      <PostCheckoutDetails
        checkoutSession={checkoutSession}
        isFreeTrial={isFreeTrial}
        isPromotional={isPromotional}
        freeTrialEndsDate={freeTrialEndsDate}
        freeTrialDuration={freeTrialDuration}
        appliedBenefitIds={appliedBenefitIds}
        promotionalDiscount={promotionalDiscount}
        promotionalEndsDate={promotionalEndsDate}
      />
    );
  }

  return (
    <PreCheckoutDetails
      checkoutSession={checkoutSession}
      isFreeTrial={isFreeTrial}
      isPromotional={isPromotional}
      appliedBenefitIds={appliedBenefitIds}
      freeTrialEndsDate={freeTrialEndsDate}
      freeTrialDuration={freeTrialDuration}
      promotionalDiscount={promotionalDiscount}
      promotionalEndsDate={promotionalEndsDate}
      setFlashbarError={setFlashbarError}
    />
  );
};

export default CheckoutSessionDetails;

import { useAmplifyConfiguration } from "../../hooks/useAmplifyConfiguration";

/*
  Borrowed from AwsTcPlatformFrontend, at least until
  the platform Auth Gateway is set up which will provide a
  common auth solution using cookies
 */

const WithAmplifyConfiguration = (Component: (props: any) => JSX.Element) => {
  return function InnerComponent(props: any) {
    useAmplifyConfiguration();
    return <Component {...props} />;
  };
};

export default WithAmplifyConfiguration;

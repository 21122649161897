import { CheckoutSession, OfferModality } from "../common/types";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Box from "@amzn/awsui-components-react/polaris/box";
import Container from "@amzn/awsui-components-react/polaris/container";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import PostCheckoutOrderSummary from "./PostCheckoutOrderSummary";
import { createCheckoutEventTrigger } from "../analytics/createCheckoutEventTrigger";

interface PostCheckoutDetailsProps {
  checkoutSession: CheckoutSession;
  isFreeTrial: boolean;
  isPromotional: boolean;
  freeTrialEndsDate: string | undefined;
  freeTrialDuration: number | undefined;
  appliedBenefitIds: string[] | undefined;
  promotionalDiscount: number | undefined;
  promotionalEndsDate: string | undefined;
}

const PostCheckoutDetails = ({
  checkoutSession,
  isFreeTrial,
  isPromotional,
  freeTrialEndsDate,
  freeTrialDuration,
  appliedBenefitIds,
  promotionalDiscount,
  promotionalEndsDate,
}: PostCheckoutDetailsProps): JSX.Element => {
  const {
    postCheckoutPage: { confirmationComponent },
  } = checkoutSession;
  return (
    <>
      <Grid
        gridDefinition={[
          {
            colspan: { xs: 3, xxs: 1, default: 1 },
          },
          {
            colspan: { xs: 6, xxs: 10, default: 10 },
          },
          {
            colspan: { xs: 3, xxs: 1, default: 1 },
          },
        ]}
      >
        <div></div>
        <SpaceBetween size="s">
          <Header variant="h1">{checkoutSession.postCheckoutPage.title}</Header>
          <Container data-testid="post_checkout_details">
            <SpaceBetween size="m">
              <SpaceBetween size="xxxs">
                <TextContent>
                  <Box
                    fontSize="heading-l"
                    fontWeight="bold"
                    padding={{ vertical: "xxs" }}
                  >
                    {confirmationComponent.heading}
                  </Box>

                  {confirmationComponent.textSections.map(
                    (textArr, textIdx) => (
                      <p key={textIdx}>{textArr.text}</p>
                    )
                  )}
                </TextContent>
              </SpaceBetween>
              {confirmationComponent.successRedirectConfig && ( // only render button if successRedirectConfig is present
                <Button
                  variant="primary"
                  href={
                    confirmationComponent.successRedirectConfig.url +
                    "?tcOrderId=" +
                    checkoutSession.createdOrder?.id
                  }
                  iconAlign="right"
                  iconName={
                    checkoutSession.items[0].offer?.modality ===
                    OfferModality.DIGITAL
                      ? undefined
                      : "external"
                  }
                  target={
                    checkoutSession.items[0].offer?.modality ===
                    OfferModality.DIGITAL
                      ? undefined
                      : "_blank"
                  }
                  data-testid="post_checkout_button"
                  onClick={() => {
                    const triggerCheckoutEvent = createCheckoutEventTrigger(
                      checkoutSession,
                      "click",
                      "My_Events",
                      "Event_checkout_completed"
                    );
                    if (typeof triggerCheckoutEvent === "function")
                      triggerCheckoutEvent();
                  }}
                >
                  {confirmationComponent.successRedirectConfig.displayText}
                </Button>
              )}
            </SpaceBetween>
          </Container>
        </SpaceBetween>
        <div></div>
      </Grid>
      <PostCheckoutOrderSummary
        checkoutSession={checkoutSession}
        isFreeTrial={isFreeTrial}
        isPromotional={isPromotional}
        freeTrialEndsDate={freeTrialEndsDate}
        freeTrialDuration={freeTrialDuration}
        appliedBenefitIds={appliedBenefitIds}
        promotionalDiscount={promotionalDiscount}
        promotionalEndsDate={promotionalEndsDate}
      />
    </>
  );
};

export default PostCheckoutDetails;

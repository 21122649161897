import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { CheckoutSession } from "../common/types";
import { generateAwsSignInUrl } from "../auth/awsSignIn";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import Link from "@amzn/awsui-components-react/polaris/link";
import { usePreventAuthRedirectParam } from "../hooks/usePreventAuthRedirectParam";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n/config";
import "./styles.scss";
import { useSearchParams } from "react-router-dom";

interface PaymentMethodProps {
  checkoutSession: CheckoutSession;
  onClickContinueToAws: () => void;
}

const PaymentMethod = ({
  checkoutSession,
  onClickContinueToAws,
}: PaymentMethodProps): JSX.Element => {
  const { t } = useTranslation();
  const dir = i18n.dir();
  const preventAuthRedirectParam = usePreventAuthRedirectParam();
  const [searchParams, setSearchParams] = useSearchParams();

  const containerStyle: React.CSSProperties =
    dir === "rtl"
      ? {
          textAlign: "right",
          direction: "rtl",
        }
      : {};
  const headerClass = dir === "rtl" ? "rtl-header" : "";

  return (
    <>
      <Container data-testid="payment_method_container">
        <div style={containerStyle}>
          <SpaceBetween size="xl">
            <Box>
              <Header className={headerClass} variant="h2">
                {t("preCheckout_paymentMethod_awsAccount_heading")}
              </Header>
              {checkoutSession?.items[0]?.offer?.modality !== "AWS_JAM" ? (
                <Box variant="p" color="text-body-secondary">
                  {t("preCheckout_paymentMethod_awsAccount_description")}
                  <Link
                    variant="primary"
                    href="https://aws.amazon.com/premiumsupport/knowledge-center/create-and-activate-aws-account/"
                    target="_blank"
                    external
                    externalIconAriaLabel={t("link_externalIconAriaLabel")}
                  >
                    {t(
                      "preCheckout_paymentMethod_awsAccount_description_learnMore"
                    )}
                  </Link>
                </Box>
              ) : (
                <div>
                  <Box
                    data-testid="preCheckout_jam_awsAccount_description_section1"
                    variant="p"
                    color="text-body-secondary"
                  >
                    {t(
                      "preCheckout_paymentMethod_awsAccount_description_requiredForPayment"
                    )}
                  </Box>
                  <Box
                    data-testid="preCheckout_jam_awsAccount_description_section2"
                    variant="p"
                    color="text-body-secondary"
                  >
                    {t(
                      "preCheckout_paymentMethod_awsAccount_description_awsFreeTierServices"
                    )}
                    <Link
                      variant="primary"
                      href="https://aws.amazon.com/premiumsupport/knowledge-center/create-and-activate-aws-account/"
                      target="_blank"
                      external
                      externalIconAriaLabel={t("link_externalIconAriaLabel")}
                    >
                      {t(
                        "preCheckout_paymentMethod_awsAccount_description_learnMore"
                      )}
                    </Link>
                  </Box>
                </div>
              )}
            </Box>
            {checkoutSession.paymentMethod ? (
              <Box data-testid="connected_aws_account_id_box">
                <SpaceBetween direction="horizontal" size="m">
                  <Box variant="strong" data-testid="connected_aws_account_id">
                    {t("preCheckout_paymentMethod_awsAccount_accountId", {
                      accountId: checkoutSession.paymentMethod.awsAccountId,
                    })}
                  </Box>
                  <Icon
                    name="status-positive"
                    size="normal"
                    variant="success"
                  />
                </SpaceBetween>
                <Link
                  data-testid="switch_aws_account_link"
                  href={generateAwsSignInUrl({
                    sessionId: checkoutSession.id,
                    sessionUserId: checkoutSession.user.id,
                    preventAuthRedirect: preventAuthRedirectParam,
                    queryParameters: searchParams.entries(),
                  })}
                >
                  {t("preCheckout_paymentMethod_awsAccount_switchAccount")}
                </Link>
              </Box>
            ) : (
              <Button
                data-testid="continue_to_aws_button"
                variant="primary"
                onClick={() => onClickContinueToAws()}
                formAction="none"
              >
                <div dir={dir}>
                  {t("preCheckout_paymentMethod_awsAccount_continue")}
                </div>
              </Button>
            )}
          </SpaceBetween>
        </div>
      </Container>
    </>
  );
};

export default PaymentMethod;

import Box from "@amzn/awsui-components-react/polaris/box";
import Link from "@amzn/awsui-components-react/polaris/link";
import { CheckoutSessionTerm, TextSection, TextStyle } from "../common/types";

interface TermSectionProps {
  section: TextSection;
}

const REPLACEMENT_KEY_REGEX = /(%\([_a-zA-Z]+\))/;

const useTermSection = (termSection: TextSection) => {
  const splitSectionParts = termSection.text
    .split(REPLACEMENT_KEY_REGEX)
    .map((inlineText, i) => {
      const linkConfig =
        inlineText.match(REPLACEMENT_KEY_REGEX) &&
        termSection.inlineLinks.find(
          (l) =>
            // inlineSpanText is of format %(REPLACEMENT_KEY), so looking for REPLACEMENT_KEY
            inlineText.substring(2, inlineText.length - 1) ===
            l.inlineReplacementKey
        );
      return {
        linkConfig,
        inlineText,
      };
    });

  return { splitSectionParts };
};

const TermSection = ({
  section: termSection,
}: TermSectionProps): JSX.Element => {
  const { splitSectionParts } = useTermSection(termSection);

  const sectionPartElements = splitSectionParts.map(
    ({ linkConfig, inlineText }, i) =>
      linkConfig ? (
        <span key={i}>
          <Link variant="primary" href={linkConfig.url} target="_blank">
            {linkConfig.displayText}
          </Link>
        </span>
      ) : (
        <span key={i}>{inlineText}</span>
      )
  );

  return termSection.style === TextStyle.BOLD ? (
    <strong>{sectionPartElements}</strong>
  ) : (
    <>{sectionPartElements}</>
  );
};

interface TermProps {
  term: CheckoutSessionTerm;
}

const Term = ({ term }: TermProps): JSX.Element => {
  return (
    <Box data-testid="term_box" color="text-body-secondary">
      {term.sections.map((termSection, idx) => {
        return <TermSection key={idx} section={termSection} />;
      })}
    </Box>
  );
};

export default Term;

import { AwsRumConfig } from "aws-rum-web";
import { getNodeEnvironment, NodeEnvironment } from "../common/nodeEnvironment";

// Add the values from your personal Isengard account after deploying AwsTcCheckoutFrontendCDK
export const DEV_ALIAS = "";
export const DEV_RUM_GUEST_ROLE_ARN = "";
export const DEV_RUM_IDENTITY_POOL_ID = "";
export const DEV_RUM_APP_ID = "";

// Constant RUM values for all stages
export const RUM_APP_VERSION = "1.0.0";
export const RUM_APP_REGION = "us-east-1";
export const RUM_APP_ENDPOINT = "https://dataplane.rum.us-east-1.amazonaws.com";
export const RUM_APP_TELEMETRIES = ["errors", "http", "performance"];

export interface RumStageProps {
  appId: string;
  rumStageConfig: AwsRumConfig;
}

export const DEV_RUM: RumStageProps = {
  appId: DEV_RUM_APP_ID,
  rumStageConfig: {
    guestRoleArn: DEV_RUM_GUEST_ROLE_ARN,
    identityPoolId: DEV_RUM_IDENTITY_POOL_ID,
    sessionSampleRate: 0.5,
  },
};

export const BETA_RUM: RumStageProps = {
  appId: "e89a9b77-2b7d-4413-ba3c-ec5e2d21c209",
  rumStageConfig: {
    guestRoleArn: "arn:aws:iam::858939866696:role/CheckoutRumGuestRole",
    identityPoolId: "us-east-1:a1e2cbd0-670a-4577-8604-e33deae4df48",
    sessionSampleRate: 0.5,
  },
};

export const GAMMA_RUM: RumStageProps = {
  appId: "3ed287f1-70b8-4eaf-bd18-15356d70697f",
  rumStageConfig: {
    guestRoleArn: "arn:aws:iam::915196742684:role/CheckoutRumGuestRole",
    identityPoolId: "us-east-1:4c3d5296-9cb8-4a61-a7dc-36ee5f15ceda",
    sessionSampleRate: 0.5,
  },
};

export const PROD_RUM: RumStageProps = {
  appId: "d5362af3-473d-4153-b101-18d7c1414153",
  rumStageConfig: {
    guestRoleArn: "arn:aws:iam::770446996841:role/CheckoutRumGuestRole",
    identityPoolId: "us-east-1:b80ed129-3e27-4e3f-8bea-d8fe8b787a82",
    sessionSampleRate: 0.5,
  },
};

export function getCurrentRumStage(): RumStageProps {
  const env = getNodeEnvironment();
  switch (env) {
    case NodeEnvironment.BETA:
      return BETA_RUM;
    case NodeEnvironment.GAMMA:
      return GAMMA_RUM;
    case NodeEnvironment.PROD:
      return PROD_RUM;
    default:
      return DEV_RUM;
  }
}

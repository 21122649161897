import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import { FRONTEND_SELECTORS } from "../common/constants";
import ErrorContent from "../components/ErrorContent";

const PageNotFound = (): JSX.Element => {
  return (
    <AppLayout
      content={
        <ContentLayout>
          <ErrorContent errorType="pageNotFound" />
        </ContentLayout>
      }
      navigationHide
      toolsHide
      disableContentPaddings
      headerSelector={FRONTEND_SELECTORS.HEADER}
      footerSelector={FRONTEND_SELECTORS.FOOTER}
    />
  );
};

export default PageNotFound;

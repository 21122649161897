import {
  CheckoutSession,
  OfferModality,
  PaymentMethodType,
} from "../common/types";
import Header from "@amzn/awsui-components-react/polaris/header";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import { Box, ColumnLayout, Container } from "@amzn/awsui-components-react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Link from "@amzn/awsui-components-react/polaris/link";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import { usePostCheckoutOrderSummary } from "../hooks/usePostCheckoutOrderSummary";
import { createCheckoutEventTrigger } from "../analytics/createCheckoutEventTrigger";
import LoadingContent from "./LoadingContent";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n/config";
import "./styles.scss";
import {
  BILLING_DOMAIN,
  SUBSCRIPTIONS,
  AWS_BILLING_CONSOLE,
} from "../common/constants";
import { getNodeEnvironment } from "../common/nodeEnvironment";

interface PostCheckoutOrderSummaryProps {
  checkoutSession: CheckoutSession;
  isFreeTrial: boolean;
  isPromotional: boolean;
  freeTrialEndsDate: string | undefined;
  freeTrialDuration: number | undefined;
  appliedBenefitIds: string[] | undefined;
  promotionalDiscount: number | undefined;
  promotionalEndsDate: string | undefined;
}

const PAYMENT_METHOD_TYPE_TO_STRING: Record<PaymentMethodType, string> = {
  [PaymentMethodType.AWS_ACCOUNT]:
    "postCheckout_orderSummary_paymentMethodString",
};

const PostCheckoutOrderSummary = ({
  checkoutSession,
  isFreeTrial,
  isPromotional,
  freeTrialEndsDate,
  freeTrialDuration,
  appliedBenefitIds,
  promotionalDiscount,
  promotionalEndsDate,
}: PostCheckoutOrderSummaryProps): JSX.Element => {
  const { t } = useTranslation();
  const dir = i18n.dir();
  const {
    applyBenefitToOfferLoading,
    subtotal,
    paymentMethod,
    formattedBillingDate,
    totalBeforeTaxes,
    displayPeriodIntlKey,
    offerModality,
  } = usePostCheckoutOrderSummary(
    checkoutSession,
    appliedBenefitIds,
    isPromotional
  );

  if (applyBenefitToOfferLoading) {
    return <LoadingContent text={t("loadingPage_loadingText_loading")} />;
  }

  const {
    postCheckoutPage: { orderSummaryComponent },
  } = checkoutSession;

  const rtlClass = dir === "rtl" ? "rtl-header" : "";
  const labelAlignment = dir === "rtl" ? "right" : "left";
  return (
    <Grid
      gridDefinition={[
        {
          colspan: { xs: 3, xxs: 1, default: 1 },
        },
        {
          colspan: { xs: 6, xxs: 10, default: 10 },
        },
        {
          colspan: { xs: 3, xxs: 1, default: 1 },
        },
      ]}
    >
      <div></div>
      <SpaceBetween size="s">
        <Container
          className={rtlClass}
          data-testid="post_checkout_order_summary"
        >
          <SpaceBetween size="xxxs">
            <SpaceBetween size="xs">
              <Header className={rtlClass} variant="h2">
                {t("postCheckout_orderSummary_summary")}
              </Header>
              <Grid
                className={rtlClass}
                gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
              >
                {orderSummaryComponent.items.map((item, itemIdx) => (
                  <Box key={itemIdx}>{item.label}</Box>
                ))}
                {orderSummaryComponent.items.map((item, itemIdx) => (
                  <Box key={itemIdx} textAlign="right">
                    {item.value}
                  </Box>
                ))}
              </Grid>
              <Grid
                className={rtlClass}
                gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
              >
                <Box>{t("postCheckout_orderSummary_orderId")}</Box>
                <Box textAlign="right">{checkoutSession.createdOrder?.id}</Box>
              </Grid>
              <Grid
                className={rtlClass}
                gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
              >
                <div>{t("postCheckout_orderSummary_paymentMethod")}</div>
                <Box textAlign="right">
                  {t(
                    PAYMENT_METHOD_TYPE_TO_STRING[
                      paymentMethod ?? PaymentMethodType.AWS_ACCOUNT
                    ]
                  ) + ` (${checkoutSession.paymentMethod?.awsAccountId})`}
                </Box>
              </Grid>
              {checkoutSession.items[0]?.offer?.modality !== "AWS_JAM" && (
                <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                  <Box>{t("postCheckout_orderSummary_nextBillingDate")}</Box>
                  <Box textAlign="right">{formattedBillingDate}</Box>
                </Grid>
              )}
              <ColumnLayout borders="horizontal" columns={1}>
                <div></div>
                <div></div>
              </ColumnLayout>
              {isFreeTrial && (
                <Grid
                  className={rtlClass}
                  data-testid="post_checkout_free_trial_days_wrapper"
                  gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
                >
                  <Box>{t("postCheckout_orderSummary_discount_label")}</Box>
                  <Box textAlign="right">
                    {t(
                      "postCheckout_orderSummary_discount_freeTrial_duration",
                      {
                        freeTrialDuration: Number.isInteger(freeTrialDuration)
                          ? freeTrialDuration
                          : (freeTrialDuration as number).toFixed(2),
                      }
                    )}
                  </Box>
                </Grid>
              )}
              <Grid
                className={rtlClass}
                gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
              >
                <Box>{t("postCheckout_orderSummary_subtotal")}</Box>
                <Box textAlign="right">
                  {t("postCheckout_orderSummary_subtotalValue", {
                    subtotal,
                  })}
                </Box>
              </Grid>
              {isPromotional && (
                <Grid
                  className={rtlClass}
                  data-testId="post_checkout_promotional_discount_wrapper"
                  gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
                >
                  <Box>
                    {t("postCheckout_orderSummary_promotional_discount_label")}
                  </Box>
                  <Box textAlign="right">
                    {t("postCheckout_orderSummary_promotional_discount_value", {
                      promotionalDiscount: promotionalDiscount,
                    })}
                  </Box>
                </Grid>
              )}
              <Grid
                className={rtlClass}
                gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
              >
                <Box>{t("postCheckout_orderSummary_totalBeforeTaxes")}</Box>
                <Box
                  data-testid="postCheckout_total_before_tax_value"
                  textAlign="right"
                >
                  {offerModality === OfferModality.DIGITAL
                    ? t(
                        "postCheckout_orderSummary_totalBeforeTaxesValue_digital",
                        {
                          totalBeforeTaxes: totalBeforeTaxes,
                        }
                      )
                    : t("postCheckout_orderSummary_totalBeforeTaxesValue", {
                        totalBeforeTaxes: totalBeforeTaxes,
                        displayPeriod: t(displayPeriodIntlKey),
                      })}
                </Box>
              </Grid>
              {isFreeTrial ? (
                <>
                  <Grid
                    className={rtlClass}
                    data-testid="post_checkout_total_due_today_wrapper"
                    gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
                  >
                    <Box>{t("postCheckout_orderSummary_total")}</Box>
                    <Box textAlign="right">
                      {t("postCheckout_orderSummary_freeTrial_total_value")}
                    </Box>
                  </Grid>
                  <Alert
                    data-testid="post_checkout_order_summary_alert"
                    statusIconAriaLabel="Info"
                  >
                    {isPromotional ? (
                      <Box
                        variant="span"
                        color="text-body-secondary"
                        data-testid="post_checkout_order_summary_alert_free_trial_and_promotional"
                      >
                        {t(
                          "preCheckout_finalizeCheckout_freeTrial_promotional_alert",
                          {
                            freeTrialEndDate: freeTrialEndsDate,
                            promotionalEndsDate: promotionalEndsDate,
                          }
                        )}
                      </Box>
                    ) : (
                      <Box
                        variant="span"
                        color="text-body-secondary"
                        data-testid="post_checkout_order_summary_alert_free_trial"
                      >
                        {t("preCheckout_finalizeCheckout_freeTrial_endDate", {
                          freeTrialEndDate: freeTrialEndsDate,
                        })}
                      </Box>
                    )}
                  </Alert>
                </>
              ) : isPromotional && !isFreeTrial ? (
                <Alert
                  data-testid="post_checkout_order_summary_alert"
                  statusIconAriaLabel="Info"
                >
                  <Box
                    variant="span"
                    color="text-body-secondary"
                    data-testid="post_checkout_order_summary_alert_promotional"
                  >
                    {t(
                      "preCheckout_finalizeCheckout_nonfreeTrial_promotional_alert",
                      {
                        promotionalEndsDate: promotionalEndsDate,
                      }
                    )}
                  </Box>
                </Alert>
              ) : (
                <>
                  <TextContent data-testid="postCheckout_fine_print">
                    <p>
                      <small>{orderSummaryComponent.subtext.text}</small>
                    </p>
                  </TextContent>
                </>
              )}
              {offerModality === OfferModality.DIGITAL ? (
                // For Digital
                <Link
                  data-testid="postCheckout_orderSummary_manageSubscriptionsLink"
                  external
                  externalIconAriaLabel={t(
                    "postCheckout_orderSummary_ariaExternalLabel"
                  )}
                  href={BILLING_DOMAIN[getNodeEnvironment()] + SUBSCRIPTIONS}
                  onFollow={() => {
                    const triggerCheckoutEvent = createCheckoutEventTrigger(
                      checkoutSession,
                      "click",
                      "Billing_console",
                      "Event_checkout_completed"
                    );
                    if (typeof triggerCheckoutEvent === "function")
                      triggerCheckoutEvent();
                  }}
                >
                  {t("postCheckout_orderSummary_manageSubscriptionsLink")}
                </Link>
              ) : (
                // For Padawan and Jams
                <Link
                  data-testid="postCheckout_orderSummary_consoleLink"
                  external
                  externalIconAriaLabel={t(
                    "postCheckout_orderSummary_ariaExternalLabel"
                  )}
                  href={AWS_BILLING_CONSOLE}
                  onFollow={() => {
                    const triggerCheckoutEvent = createCheckoutEventTrigger(
                      checkoutSession,
                      "click",
                      "Billing_console",
                      "Event_checkout_completed"
                    );
                    if (typeof triggerCheckoutEvent === "function")
                      triggerCheckoutEvent();
                  }}
                >
                  {t("postCheckout_orderSummary_consoleLink")}
                </Link>
              )}
            </SpaceBetween>
          </SpaceBetween>
        </Container>
      </SpaceBetween>
      <div></div>
    </Grid>
  );
};

export default PostCheckoutOrderSummary;

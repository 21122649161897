import { Auth, CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { gql, useLazyQuery } from "@apollo/client";
import { CognitoIdToken, CognitoUser } from "amazon-cognito-identity-js";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { ROUTE_ACCESS_DENIED } from "../common/routes";
import i18n, { getUserLanguage } from "../../i18n/config";
import {
  LEARNING_CONSOLE_USER_ID_KEY,
  LEARNING_CONSOLE_USER_ROLE_KEY,
} from "../common/constants";
import { getNodeEnvironment } from "../common/nodeEnvironment";
import { handleEmailVerification } from "./useAmplifyConfiguration";

/*
  Borrowed from AwsTcPlatformFrontend, at least until
  the platform Auth Gateway is set up which will provide a
  common auth solution using cookies
 */

export class ApplicationUserIdToken {
  readonly email!: string;

  constructor(cognitoIdToken: CognitoIdToken) {
    return Object.assign({}, this, cognitoIdToken.payload);
  }
}

export enum RoleType {
  SERVICE = "SERVICE",
  SUPER_USER = "SUPER_USER",
  INTERNAL_EMPLOYEE = "INTERNAL_EMPLOYEE",
  LEARNING_ADMIN = "LEARNING_ADMIN",
  POST_AUTH_PRE_VIAS = "POST_AUTH_PRE_VIAS",
  LEARNING_ACCOUNT_IT_CONTACT = "LEARNING_ACCOUNT_IT_CONTACT",
  LEARNER = "LEARNER",
}

export interface ApplicationUserInitProps {
  user?: CognitoUser;
  isLoggedIn?: boolean;
  role?: RoleType;
  id?: string;
  audiencePath?: string;
  emailAddress?: string;
  locale?: string;
  userVerified?: boolean;
}

export class ApplicationUser {
  readonly user?: CognitoUser;
  readonly isLoggedIn?: boolean;
  readonly role?: RoleType;
  readonly id?: string;
  readonly audiencePath?: string;
  readonly emailAddress?: string;
  readonly locale?: string;
  readonly userVerified?: boolean;

  constructor(initProps: ApplicationUserInitProps) {
    this.user = initProps.user;
    this.isLoggedIn = initProps.isLoggedIn;
    this.role = initProps.role;
    this.audiencePath = initProps.audiencePath;
    this.locale = initProps.locale;
    this.userVerified = initProps.userVerified;
  }

  public get idToken(): ApplicationUserIdToken | undefined {
    const cognitoIdToken = this.user?.getSignInUserSession()?.getIdToken();
    return cognitoIdToken
      ? new ApplicationUserIdToken(cognitoIdToken)
      : undefined;
  }
}

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      id
      emailAddress
      firstName
      lastName
      status
      userRole
      auditMetadata {
        createdAt
      }
      gandalfDetails {
        audiencePath
      }
    }
  }
`;

export const useGetCurrentUserLazy = () => {
  return useLazyQuery(GET_CURRENT_USER, {});
};

export const refreshSessionAndJWT = async (
  manualRefresh = false
): Promise<any> => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    const accessTokenTimeDifferenceSeconds =
      (currentSession.getAccessToken().getExpiration() * 1000 - Date.now()) /
      1000;
    if (accessTokenTimeDifferenceSeconds <= 30 || manualRefresh) {
      // refresh token is within 30 seconds of expiring/has expired so let's get a new one
      user.refreshSession(currentSession.getRefreshToken());
    }
    return user;
  } catch (error) {
    return error;
  }
};

export const verifyViasAndRefreshUserJWT = async (
  setSignedInUser,
  maxRetries = 2,
  delayMs = 1000
) => {
  const refreshToken = async () => {
    const fetchedUser = await refreshSessionAndJWT(true);
    setSignedInUser(fetchedUser);

    return (
      fetchedUser?.signInUserSession.idToken.payload[
        LEARNING_CONSOLE_USER_ID_KEY[getNodeEnvironment()]
      ] !== undefined &&
      fetchedUser?.signedInUser?.idToken.payload[
        LEARNING_CONSOLE_USER_ROLE_KEY[getNodeEnvironment()]
      ] !== undefined
    );
  };
  let viasAttributeFound = await refreshToken();
  let retries = 1;
  while (!viasAttributeFound && retries <= maxRetries) {
    await new Promise((resolve) => {
      setTimeout(resolve, delayMs);
    });
    viasAttributeFound = await refreshToken();
    retries++;
  }
  return viasAttributeFound;
};

export const useAuth = (
  preventAuthRedirect = false
): { applicationUser: ApplicationUser; refetchAuth: any } => {
  const [signedInUser, setSignedInUser] = useState<CognitoUser | undefined>(
    undefined
  );
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(undefined);
  const [userRole, setUserRole] = useState<RoleType | undefined>(undefined);
  const [audiencePath, setAudiencePath] = useState<string | undefined>(
    undefined
  );
  const [locale, setLocale] = useState<string | undefined>(undefined);
  const [userVerified, setUserVerified] = useState<boolean>(false);
  const [getCurrentUser, { data, error }] = useGetCurrentUserLazy();
  const navigate = useNavigate();
  const location = useLocation();

  const authenticateUser = () => {
    // Attempt signin
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setSignedInUser(user);
      })
      .then(() => {
        getCurrentUser();
      })
      .catch(() => {
        if (!preventAuthRedirect) {
          Auth.federatedSignIn({
            customProvider: CognitoHostedUIIdentityProvider.Cognito,
            customState: JSON.stringify({
              pathname: location.pathname,
              search: location.search,
            }),
          });
        }
      });
  };

  useEffect(() => {
    if (data) {
      if (data.currentUser.status !== "ACTIVE") {
        setIsLoggedIn(false);
        navigate(ROUTE_ACCESS_DENIED);
      } else {
        // E2E test user should bypass the VIAS verification logic here since:
        // 1. Supergraph overrides user info in E2E requests with one hard-coded post-VIAS test user.
        // Therefore, it is not necessary
        // 2. The Gandalf E2E temp creds token decoded here is not synced with VIAS
        // (which would not affect Supergraph calls due to reason (1))
        // Therefore, bypassing it could avoid timed out VIAS validations in E2E waitUntil() logic
        if (
          signedInUser?.getSignInUserSession()?.getIdToken().payload[
            LEARNING_CONSOLE_USER_ID_KEY[getNodeEnvironment()]
          ] !== undefined ||
          signedInUser?.getSignInUserSession()?.getIdToken().payload
            .public_provider_name === "testProvider"
        ) {
          setUserVerified(true);
        } else {
          (async () => {
            const hasViasVerfied = await verifyViasAndRefreshUserJWT(
              setSignedInUser
            );
            if (hasViasVerfied) {
              setUserVerified(true);
            }
          })();
        }
        setIsLoggedIn(true);
        setUserRole(data.currentUser.userRole);

        setAudiencePath(
          signedInUser?.getSignInUserSession()?.getIdToken().payload
            .public_provider_name
        );

        i18n.changeLanguage(getUserLanguage());
        setLocale(getUserLanguage());
      }
    }
    if (error) {
      if (
        error.message.includes(
          "EmailAddress is not verified, verification required"
        )
      ) {
        handleEmailVerification(
          signedInUser?.getSignInUserSession()?.getIdToken().payload
            .public_provider_name
        );
      } else {
        setIsLoggedIn(false);
        navigate(ROUTE_ACCESS_DENIED);
      }
    }
  }, [data, signedInUser, error, navigate]);

  useEffect(() => {
    authenticateUser();
  }, []);

  return {
    applicationUser: new ApplicationUser({
      user: signedInUser,
      isLoggedIn: isLoggedIn,
      role: userRole,
      audiencePath: audiencePath,
      locale: locale,
      userVerified: userVerified,
    }),
    refetchAuth: authenticateUser,
  };
};

import { CheckoutSession, OfferModality } from "../common/types";
import { createEventTrigger } from "./createEventTrigger";

const EVENT_CONFIG_BY_MODALITY: Record<
  OfferModality,
  { triggerEvents: boolean; prefix: string }
> = {
  AWS_JAM: {
    triggerEvents: true,
    prefix: "AWS_Jam",
  },
  DIGITAL: {
    triggerEvents: true,
    prefix: "docebo",
  },
  PADAWAN: {
    triggerEvents: false, // set it to true if needed in future
    prefix: "Padawan",
  },
};

export const createCheckoutEventTrigger = (
  checkoutSession: CheckoutSession,
  eventType: string,
  eventSuffix: string,
  eventStatus: string,
  eventPeriod?: string
) => {
  const offerModality = checkoutSession.items[0].offer?.modality;
  const offerName = checkoutSession.items[0].offer?.name;
  if (!offerModality || !offerName) {
    throw new Error("No modality or offer name found for checkoutSession");
  }
  const config = EVENT_CONFIG_BY_MODALITY[offerModality];
  const { prefix, triggerEvents } = config;
  if (triggerEvents === true) {
    if (prefix === "docebo" && eventStatus === "B2I") {
      return createEventTrigger(
        eventType,
        `${prefix}|${eventStatus}|${eventPeriod} Subscription|${eventSuffix}`
      );
    } else if (prefix === "AWS_Jam") {
      return createEventTrigger(
        eventType,
        `${prefix}|${eventStatus}|${offerName}|${eventSuffix}`
      );
    }
  }
  return () => undefined;
};

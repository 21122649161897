import Box from "@amzn/awsui-components-react/polaris/box";
import styled from "styled-components";
import "./CheckoutFooter.scss";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Link from "@amzn/awsui-components-react/polaris/link";
import CookieConsentManager from "../../cookieManagement/CookieConsentManager";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n/config";

const CenteredTextContent = styled(TextContent)`
  text-align: center;
  align-content: center;
`;

const currentYear = new Date().getFullYear().toString();

const CheckoutFooter = (): JSX.Element => {
  const { t } = useTranslation();
  const dir = i18n.dir();
  const cookieConsentManager = new CookieConsentManager();

  return (
    <div className="footer-wrapper">
      <footer className="checkout-page-footer">
        <nav>
          <CenteredTextContent>
            <div>
              <SpaceBetween direction="horizontal" size="s">
                <div className="footer__actions">
                  <Link
                    data-testid="privacy_link"
                    href="https://aws.amazon.com/privacy/"
                  >
                    <div dir={dir}>{t("footer_link_privacy")}</div>
                  </Link>
                </div>
                <div>|</div>
                <div className="footer__actions">
                  <Link
                    data-testid="terms_link"
                    href="https://aws.amazon.com/terms/"
                  >
                    <div dir={dir}>{t("footer_link_terms")}</div>
                  </Link>
                </div>
                <div>|</div>
                <div className="footer__actions">
                  <Box>
                    <Link
                      data-testid="cookie_preferences"
                      onFollow={cookieConsentManager.customizeCookies}
                    >
                      <div dir={dir}>{t("footer_link_cookiePreferences")}</div>
                    </Link>
                  </Box>
                </div>
                <div>|</div>
                <div className="footer__actions">
                  <Box data-testid="copy_right">
                    <div dir={dir}>
                      {t("footer_text_copyRight", {
                        year: currentYear,
                      })}
                    </div>
                  </Box>
                </div>
              </SpaceBetween>
            </div>
          </CenteredTextContent>
        </nav>
      </footer>
    </div>
  );
};

export default CheckoutFooter;

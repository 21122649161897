import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import Container from "@amzn/awsui-components-react/polaris/container";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Header from "@amzn/awsui-components-react/polaris/header";
import Modal, { ModalProps } from "@amzn/awsui-components-react/polaris/modal";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import styled from "styled-components";
import { generateAwsSignInUrl } from "../auth/awsSignIn";
import { CheckoutSession } from "../common/types";
import { usePreventAuthRedirectParam } from "../hooks/usePreventAuthRedirectParam";
import { createCheckoutEventTrigger } from "../analytics/createCheckoutEventTrigger";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n/config";
import "./styles.scss";
import { AWS_SIGN_UP_URL } from "../common/constants";
import { NodeEnvironment, getNodeEnvironment } from "../common/nodeEnvironment";
import { useSearchParams } from "react-router-dom";

const CenterBox = styled(Box)<{ justifyContentEnd?: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  ${(props) => props.justifyContentEnd && "justify-content: end;"};
`;

interface AwsAccountModalProps
  extends Pick<ModalProps, "onDismiss" | "visible"> {
  checkoutSession: CheckoutSession;
}

const generateAwsSignUpFullUrl = (
  redirectUrl: string,
  stage: NodeEnvironment
) => {
  if (stage === NodeEnvironment.GAMMA || stage === NodeEnvironment.PROD) {
    return AWS_SIGN_UP_URL[stage] + "?redirect_url=" + redirectUrl;
  }
  return AWS_SIGN_UP_URL[stage];
};

const AwsAccountModal = ({
  checkoutSession,
  ...props
}: AwsAccountModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dir = i18n.dir();
  const preventAuthRedirectParam = usePreventAuthRedirectParam();
  const rtlClass = dir === "rtl" ? "rtl-header" : "";
  const textAlignmnet = dir === "rtl" ? "right" : "left";

  const encodedRedirectUrl = encodeURI(window.location.href);

  return (
    <Box data-testid="aws_modal_wrapper_box">
      <Modal
        closeAriaLabel={t(
          "preCheckout_paymentMethod_awsAccount_modal_close_ariaLabel"
        )}
        header={
          <Header className={rtlClass} variant="h2">
            {t("preCheckout_paymentMethod_awsAccount_continue")}
          </Header>
        }
        size="large"
        {...props}
      >
        <SpaceBetween size="s">
          <Container
            variant="stacked"
            data-testid="aws_modal_sign_in_container"
          >
            <Grid
              className={rtlClass}
              gridDefinition={[
                {
                  colspan: { default: 6, xs: 1 },
                },
                {
                  colspan: { default: 12, xs: 6 },
                },
                {
                  colspan: { default: 12, xs: 5 },
                },
              ]}
            >
              <CenterBox>
                <img
                  src="/public/aws-logo.svg"
                  alt={t("preCheckout_paymentMethod_awsAccount_modal_logo_alt")}
                />
              </CenterBox>

              <CenterBox>
                <Box
                  textAlign={textAlignmnet}
                  fontSize="heading-m"
                  padding={{ vertical: "xxs" }}
                >
                  {t(
                    "preCheckout_paymentMethod_awsAccount_modal_signIn_heading"
                  )}
                </Box>
              </CenterBox>

              <CenterBox justifyContentEnd>
                <Button
                  className={rtlClass}
                  data-testid="aws_modal_sign_in_button"
                  onClick={() => {
                    const triggerCheckoutEvent = createCheckoutEventTrigger(
                      checkoutSession,
                      "click",
                      "Add_payment_sign_in",
                      "Event_checkout"
                    );
                    if (typeof triggerCheckoutEvent === "function")
                      triggerCheckoutEvent();
                  }}
                  href={generateAwsSignInUrl({
                    sessionId: checkoutSession.id,
                    sessionUserId: checkoutSession.user.id,
                    preventAuthRedirect: preventAuthRedirectParam,
                    queryParameters: Object.fromEntries(searchParams),
                  })}
                  formAction="none"
                >
                  {t(
                    "preCheckout_paymentMethod_awsAccount_modal_signIn_button"
                  )}
                </Button>
              </CenterBox>
            </Grid>
          </Container>

          <Container variant="stacked" data-testid="aws_modal_create_container">
            <Grid
              className={rtlClass}
              gridDefinition={[
                {
                  colspan: { default: 6, xs: 1 },
                },
                {
                  colspan: { default: 12, xs: 6 },
                },
                {
                  colspan: { default: 12, xs: 5 },
                },
              ]}
            >
              <CenterBox>
                <img
                  src="/public/aws-logo.svg"
                  alt={t("preCheckout_paymentMethod_awsAccount_modal_logo_alt")}
                />
              </CenterBox>

              <CenterBox>
                <Box>
                  <Box
                    textAlign={textAlignmnet}
                    fontSize="heading-m"
                    padding={{ vertical: "xxs" }}
                  >
                    {t(
                      "preCheckout_paymentMethod_awsAccount_modal_create_heading"
                    )}
                  </Box>
                  <Box color="text-body-secondary">
                    {t(
                      "preCheckout_paymentMethod_awsAccount_modal_create_return"
                    )}
                  </Box>
                </Box>
              </CenterBox>

              <CenterBox justifyContentEnd>
                <Button
                  className={rtlClass}
                  data-testid="aws_modal_create_button"
                  onClick={() => {
                    const triggerCheckoutEvent = createCheckoutEventTrigger(
                      checkoutSession,
                      "click",
                      "Add_payment_sign_up",
                      "Event_checkout"
                    );
                    if (typeof triggerCheckoutEvent === "function")
                      triggerCheckoutEvent();
                  }}
                  href={generateAwsSignUpFullUrl(
                    encodedRedirectUrl,
                    getNodeEnvironment()
                  )}
                  formAction="none"
                >
                  {t(
                    "preCheckout_paymentMethod_awsAccount_modal_create_button"
                  )}
                </Button>
              </CenterBox>
            </Grid>
          </Container>
        </SpaceBetween>
      </Modal>
    </Box>
  );
};

export default AwsAccountModal;

import { NodeEnvironment } from "./nodeEnvironment";

export const FRONTEND_SELECTORS = {
  APP: "#app",
  HEADER: "#h",
  FOOTER: ".footer-wrapper",
};

export const DOCEBO_ROUTE_CATALOG = "/learn/catalog";
export const SUBSCRIPTIONS = "/subscriptions";
export const USER_HAS_NO_DOCEBO_ID_ERROR =
  "Cannot create checkout session since user does not have associated docebo user.";

export const APPLICATION_URL: Record<NodeEnvironment, string> = {
  [NodeEnvironment.TEST]: "http://localhost:3000",
  [NodeEnvironment.DEVELOPMENT]: "http://localhost:3000",
  [NodeEnvironment.BETA]:
    "https://beta.checkoutv2.skillbuilder.training.aws.dev",
  [NodeEnvironment.GAMMA]: "https://checkoutv2.skillbuilder.training.aws.dev",
  [NodeEnvironment.PROD]: "https://checkoutv2.skillbuilder.aws",
};

export const BILLING_DOMAIN: Record<NodeEnvironment, string> = {
  [NodeEnvironment.TEST]: "https://dmlhdhm0oulht.cloudfront.net",
  [NodeEnvironment.DEVELOPMENT]: "https://dmlhdhm0oulht.cloudfront.net",
  [NodeEnvironment.BETA]: "https://dmlhdhm0oulht.cloudfront.net",
  [NodeEnvironment.GAMMA]: "https://billing.skillbuilder.training.aws.dev",
  [NodeEnvironment.PROD]: "https://billing.skillbuilder.aws",
};

// AWS Sign Up team only allowlisted our Gamma and Prod domains for redirect
// So other stages keep using the general AWS Sign Up URL
export const AWS_SIGN_UP_URL: Record<NodeEnvironment, string> = {
  [NodeEnvironment.TEST]: "https://portal.aws.amazon.com/billing/signup#/start",
  [NodeEnvironment.DEVELOPMENT]:
    "https://portal.aws.amazon.com/billing/signup#/start",
  [NodeEnvironment.BETA]: "https://portal.aws.amazon.com/billing/signup#/start",
  [NodeEnvironment.GAMMA]:
    "https://aws-portal-preprod.amazon.com/billing/signup",
  [NodeEnvironment.PROD]: "https://portal.aws.amazon.com/billing/signup",
};

export const SUBSCRIPTION_PAGE_URL: Record<NodeEnvironment, string> = {
  [NodeEnvironment.TEST]:
    "https://explore.skillbuilder.training.aws.dev/pages/59/subscriptions",
  [NodeEnvironment.DEVELOPMENT]:
    "https://explore.skillbuilder.training.aws.dev/pages/59/subscriptions",
  [NodeEnvironment.BETA]:
    "https://explore.skillbuilder.training.aws.dev/pages/59/subscriptions",
  [NodeEnvironment.GAMMA]:
    "https://explore.skillbuilder.training.aws.dev/pages/59/subscriptions",
  [NodeEnvironment.PROD]:
    "https://explore.skillbuilder.aws/pages/59/subscriptions",
};

export const LEARNING_CONSOLE_USER_ID_KEY: Record<NodeEnvironment, string> = {
  [NodeEnvironment.TEST]: "aws/learningconsole/beta:userId",
  [NodeEnvironment.DEVELOPMENT]: "aws/learningconsole/beta:userId",
  [NodeEnvironment.BETA]: "aws/learningconsole/beta:userId",
  [NodeEnvironment.GAMMA]: "aws/learningconsole/gamma:userId",
  [NodeEnvironment.PROD]: "aws/learningconsole/prod:userId",
};

export const LEARNING_CONSOLE_USER_ROLE_KEY: Record<NodeEnvironment, string> = {
  [NodeEnvironment.TEST]: "aws/learningconsole/beta:userRole",
  [NodeEnvironment.DEVELOPMENT]: "aws/learningconsole/beta:userRole",
  [NodeEnvironment.BETA]: "aws/learningconsole/beta:userRole",
  [NodeEnvironment.GAMMA]: "aws/learningconsole/gamma:userRole",
  [NodeEnvironment.PROD]: "aws/learningconsole/prod:userRole",
};

export const E2E_TEST_ENV_KEY = "e2eTestEnvironment";

export const UNSUPPORTED_REGION = "unsupported region";

export const ACCESS_DENIED = "do not have permission to perform this operation";

export const HAS_PENDING_PAYMENTS = "already has associated pending payments";

export const AWS_BILLING_CONSOLE =
  "https://console.aws.amazon.com/billing/home#/bills";

export const TEMP_CREDS_SET_EVENT = "temp-creds-set";

export const SEARCH_PARAM_PREVENT_AUTH_REDIRECT = "preventAuthRedirect";

export const OFFER_ID_BY_BILLING_PERIOD_APN_BETA: Record<string, string> = {
  monthly: "E974F16A-6407-47F8-8E83-63F08C47D470",
  yearly: "f9050881-1b3d-4ef3-8b8a-df873d89bcf9",
};

export const OFFER_ID_BY_BILLING_PERIOD_NON_APN_BETA: Record<string, string> = {
  monthly: "08E9475B-D383-4C6E-9514-7CAE547F8109",
  yearly: "a2430740-a616-4956-996e-3fc531129ecc",
};

export const OFFER_ID_BY_BILLING_PERIOD_APN_GAMMA: Record<string, string> = {
  monthly: "74b62785-8908-44d6-8d77-68b8f2b5ed18",
  yearly: "54bbe351-b169-4f78-9c6c-272368ca0f90",
};

export const OFFER_ID_BY_BILLING_PERIOD_NON_APN_GAMMA: Record<string, string> =
  {
    monthly: "6cd34855-f591-4c33-972f-042480d9e93c",
    yearly: "77371b6b-1390-4343-9300-6c4b6d4cb063",
  };

export const OFFER_ID_BY_BILLING_PERIOD_APN_PROD: Record<string, string> = {
  monthly: "a98bef90-ad88-49a0-b33f-1c957a19ea4a",
  yearly: "748fab5e-e199-42cd-bb5c-b3fa86b2152a",
};

export const OFFER_ID_BY_BILLING_PERIOD_NON_APN_PROD: Record<string, string> = {
  monthly: "fba18379-dfbe-477a-9931-6a78bb68507b",
  yearly: "45757aeb-b1fc-480b-a647-ad35b09df609",
};

export const OFFER_ID_BY_ENV: Record<
  NodeEnvironment,
  Array<Record<string, string>>
> = {
  [NodeEnvironment.DEVELOPMENT]: [
    OFFER_ID_BY_BILLING_PERIOD_APN_BETA,
    OFFER_ID_BY_BILLING_PERIOD_NON_APN_BETA,
  ],
  [NodeEnvironment.TEST]: [
    OFFER_ID_BY_BILLING_PERIOD_APN_BETA,
    OFFER_ID_BY_BILLING_PERIOD_NON_APN_BETA,
  ],
  [NodeEnvironment.BETA]: [
    OFFER_ID_BY_BILLING_PERIOD_APN_BETA,
    OFFER_ID_BY_BILLING_PERIOD_NON_APN_BETA,
  ],
  [NodeEnvironment.GAMMA]: [
    OFFER_ID_BY_BILLING_PERIOD_APN_GAMMA,
    OFFER_ID_BY_BILLING_PERIOD_NON_APN_GAMMA,
  ],
  [NodeEnvironment.PROD]: [
    OFFER_ID_BY_BILLING_PERIOD_APN_PROD,
    OFFER_ID_BY_BILLING_PERIOD_NON_APN_PROD,
  ],
};

export const DOCEBO_DOMAIN = {
  [NodeEnvironment.TEST]: "https://aws-alpha4.docebosaas.com",
  [NodeEnvironment.DEVELOPMENT]: "https://aws-alpha4.docebosaas.com",
  [NodeEnvironment.BETA]: "https://aws-preprod.docebosaas.com",
  [NodeEnvironment.GAMMA]: "https://explore.skillbuilder.training.aws.dev",
  [NodeEnvironment.PROD]: "https://explore.skillbuilder.aws",
};

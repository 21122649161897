import { CREATE_SB_DIGITAL_CHECKOUT_SESSION } from "../common/api/mutations";
import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { OFFER_ID_BY_ENV } from "../common/constants";
import { getNodeEnvironment } from "../common/nodeEnvironment";

export const useMiddleManContent = (
  isLoggedIn: boolean | undefined,
  audiencePath: string | undefined,
  offerBillingPeriod: string | undefined,
  locale: string | undefined,
  userVerified: boolean | undefined
): {
  isUserAllowedToCheckout: boolean | undefined;
  createSBDigitalCheckoutSessionError: any;
  createSBDigitalCheckoutSessionLoading: boolean;
  checkoutSessionRedirectUrl: string | undefined;
  isUnSupportedAudiencePath: boolean;
} => {
  const [checkoutSessionRedirectUrl, setCheckoutSessionRedirectUrl] =
    useState<string>();
  const [isUserAllowedToCheckout, setIsUserAllowedToCheckout] = useState<
    boolean | undefined
  >(undefined);
  const [offerId, setOfferId] = useState<string | undefined>(undefined);
  const [isUnSupportedAudiencePath, setIsUnSupportedAudiencePath] =
    useState<boolean>(false);

  const [
    createNewSbDigitalOrderCheckoutSession,
    {
      data: createSBDigitalCheckoutSessionData,
      error: createSBDigitalCheckoutSessionError,
      loading: createSBDigitalCheckoutSessionLoading,
    },
  ] = useMutation(CREATE_SB_DIGITAL_CHECKOUT_SESSION);

  useEffect(() => {
    if (
      isLoggedIn &&
      offerBillingPeriod &&
      audiencePath &&
      audiencePath !== "AmazonFederate"
    ) {
      if (audiencePath === "AWSPartnerNetwork") {
        setOfferId(
          OFFER_ID_BY_ENV[getNodeEnvironment()][0][offerBillingPeriod]
        );
      } else if (
        audiencePath === "LoginWithAmazon" ||
        audiencePath === "AWSBuilderID" ||
        audiencePath === "testProvider"
      ) {
        setOfferId(
          OFFER_ID_BY_ENV[getNodeEnvironment()][1][offerBillingPeriod]
        );
      } else if (audiencePath !== "AmazonFederate") {
        setIsUnSupportedAudiencePath(true);
      }
    }
  }, [audiencePath, offerBillingPeriod, isLoggedIn]);

  useEffect(() => {
    if (userVerified && offerId && locale) {
      createNewSbDigitalOrderCheckoutSession({
        variables: {
          input: {
            offerId: offerId,
            locale: locale,
          },
        },
      });
    }
  }, [userVerified, offerId, locale, createNewSbDigitalOrderCheckoutSession]);

  useEffect(() => {
    if (
      createSBDigitalCheckoutSessionData?.createNewSbDigitalOrderCheckoutSession
    ) {
      setCheckoutSessionRedirectUrl(
        createSBDigitalCheckoutSessionData?.createNewSbDigitalOrderCheckoutSession
      );
      setIsUserAllowedToCheckout(true);
    } else if (
      createSBDigitalCheckoutSessionData?.createNewSbDigitalOrderCheckoutSession ===
      null
    ) {
      setIsUserAllowedToCheckout(false);
    }
  }, [
    createSBDigitalCheckoutSessionData?.createNewSbDigitalOrderCheckoutSession,
  ]);

  return {
    isUserAllowedToCheckout,
    createSBDigitalCheckoutSessionError,
    createSBDigitalCheckoutSessionLoading,
    checkoutSessionRedirectUrl,
    isUnSupportedAudiencePath,
  };
};

import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  AWS_SIGN_IN_LITERAL,
  CheckoutAwsSignInState,
  SEARCH_PARAM_CODE,
  SEARCH_PARAM_STATE,
  fetchAwsAccountJwt,
  isUserRoot,
} from "../auth/awsSignIn";
import { useAuth } from "./useAuth";
import jwtDecode from "jwt-decode";
import { useMutation } from "@apollo/client";
import {
  ADD_PAYMENT_METHOD_TO_CHECKOUT_SESSION,
  CREATE_PAYMENT_METHOD,
} from "../common/api/mutations";
import { FlashbarError } from "../pages/CheckoutSessionMain";
import {
  SEARCH_PARAM_PREVENT_AUTH_REDIRECT,
  UNSUPPORTED_REGION,
  ACCESS_DENIED,
  HAS_PENDING_PAYMENTS,
} from "../common/constants";
import { useOnTempCredsUpdated } from "./useOnTempCredsUpdated";

export const useHandleAwsSignIn = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [awsAccountJwt, setAWSAccountJwt] = useState<string>();
  const [awsAccountId, setAwsAccountId] = useState<string>();
  const [paymentMethodId, setPaymentMethodId] = useState<string>();
  const [queryParameters, setQueryParameters] = useState<string>();
  const [awsSignInFlashbarError, setAwsSignInFlashbarError] =
    useState<FlashbarError>();
  const [tempCredsUpdated, setTempCredsUpdated] = useState(false);

  const [
    createPaymentMethod,
    { data: createPaymentMethodData, error: createPaymentMethodError },
  ] = useMutation(CREATE_PAYMENT_METHOD);

  const [addPaymentMethodToSession, { error: addPaymentMethodToSessionError }] =
    useMutation(ADD_PAYMENT_METHOD_TO_CHECKOUT_SESSION);

  const paramsParsed = new URLSearchParams(location.search);
  const searchParamCode = paramsParsed.get(SEARCH_PARAM_CODE);
  const searchParamAuthState = paramsParsed.get(SEARCH_PARAM_STATE);

  const authState: CheckoutAwsSignInState | undefined = useMemo(() => {
    if (searchParamAuthState) {
      const parsedState: CheckoutAwsSignInState =
        JSON.parse(searchParamAuthState);
      if (parsedState.signInType === AWS_SIGN_IN_LITERAL) {
        if (parsedState.queryParameters !== undefined) {
          let parameters = "";
          for (const key of Object.keys(parsedState.queryParameters)) {
            parameters += `&${key}=${parsedState.queryParameters[key]}`;
          }
          setQueryParameters(parameters);
        }
        return parsedState;
      }
    }

    return undefined;
  }, [searchParamAuthState]);

  useAuth(authState?.preventAuthRedirect);

  useOnTempCredsUpdated(() => setTempCredsUpdated(true));

  // When we have a code & auth state from AWS Sign in, fetch AWS JWT
  useEffect(() => {
    // only start fetchAwsAccountJwt if auth is done (whether in E2E tests w/ temp creds
    // or using standard amplify auth)
    // Otherwise, the fetch call may be canceled during redirect for amplify/gandalf auth
    // and so the code will be deleted in the IDP
    if (
      searchParamCode &&
      authState &&
      (authState.preventAuthRedirect
        ? tempCredsUpdated
        : location.state?.redirectFromAmplifyAuth)
    ) {
      try {
        fetchAwsAccountJwt(searchParamCode).then(setAWSAccountJwt);
      } catch (e: any) {
        console.error("Issue fetching AWS account JWT", e);
      }
    }
  }, [
    searchParamCode,
    authState,
    location.state?.redirectFromAmplifyAuth,
    setAWSAccountJwt,
    tempCredsUpdated,
  ]);

  // When we get a JWT, validate it and save account ID
  useEffect(() => {
    if (awsAccountJwt) {
      const payload = jwtDecode<any>(awsAccountJwt);
      if (payload) {
        if (isUserRoot(payload)) {
          setAwsAccountId(payload.name);
        } else {
          setAwsSignInFlashbarError(FlashbarError.NOT_ROOT_USER);
        }
      }
    }
  }, [awsAccountJwt]);

  // When we have a valid account ID in a JWT, create a payment method
  useEffect(() => {
    if (authState?.sessionUserId && awsAccountJwt && awsAccountId) {
      createPaymentMethod({
        variables: {
          input: {
            userId: authState.sessionUserId,
            awsToken: awsAccountJwt,
          },
        },
      });
    }
  }, [
    awsAccountId,
    awsAccountJwt,
    authState?.sessionUserId,
    createPaymentMethod,
  ]);

  // When a payment method is finished being created, save it in sate
  useEffect(() => {
    if (createPaymentMethodData?.paymentMethod?.id) {
      setPaymentMethodId(createPaymentMethodData.paymentMethod.id);
    }
  }, [createPaymentMethodData?.paymentMethod?.id]);

  // When we have a valid payment method & a session, add method to session then redirect
  useEffect(() => {
    if (paymentMethodId && authState?.sessionId) {
      const sessionPath = authState.preventAuthRedirect
        ? `/sessions/${authState.sessionId}?${SEARCH_PARAM_PREVENT_AUTH_REDIRECT}=true` +
          queryParameters
        : `/sessions/${authState.sessionId}` +
          (queryParameters ? "?" + queryParameters.substring(1) : "");

      addPaymentMethodToSession({
        variables: {
          input: {
            sessionId: authState.sessionId,
            paymentMethodId,
          },
        },
        onCompleted: () =>
          navigate(sessionPath, {
            replace: true,
          }),
      });
    }
  }, [
    navigate,
    addPaymentMethodToSession,
    authState?.sessionId,
    authState?.preventAuthRedirect,
    paymentMethodId,
  ]);

  // If there is an error either creating payment method or
  // saving to session, save the error type
  useEffect(() => {
    if (
      createPaymentMethodError &&
      createPaymentMethodError.message.includes(ACCESS_DENIED)
    ) {
      setAwsSignInFlashbarError(FlashbarError.ERROR_UNAUTHORIZED_PAYMENT);
    } else if (
      createPaymentMethodError &&
      createPaymentMethodError.message.includes(UNSUPPORTED_REGION)
    ) {
      setAwsSignInFlashbarError(FlashbarError.ERROR_INVALID_MARKETPLACE_GROUP);
    } else if (
      addPaymentMethodToSessionError &&
      addPaymentMethodToSessionError.message.includes(UNSUPPORTED_REGION)
    ) {
      setAwsSignInFlashbarError(FlashbarError.ERROR_INVALID_PAYMENT_REGION);
    } else if (
      addPaymentMethodToSessionError &&
      addPaymentMethodToSessionError.message.includes(HAS_PENDING_PAYMENTS)
    ) {
      setAwsSignInFlashbarError(FlashbarError.ALREADY_HAS_PENDING_PAYMENTS);
    } else if (createPaymentMethodError || addPaymentMethodToSessionError) {
      setAwsSignInFlashbarError(FlashbarError.ERROR_SAVING_ACCOUNT);
    }
  }, [createPaymentMethodError, addPaymentMethodToSessionError]);

  // If a sign in error, redirect to session page with error in state
  useEffect(() => {
    if (awsSignInFlashbarError && authState?.sessionId) {
      const sessionPath = authState.preventAuthRedirect
        ? `/sessions/${authState.sessionId}?${SEARCH_PARAM_PREVENT_AUTH_REDIRECT}=true` +
          queryParameters
        : `/sessions/${authState.sessionId}` +
          (queryParameters ? "?" + queryParameters.substring(1) : "");
      navigate(sessionPath, {
        replace: true,
        state: {
          flashbarError: awsSignInFlashbarError,
        },
      });
    }
  }, [
    awsSignInFlashbarError,
    authState?.sessionId,
    authState?.preventAuthRedirect,
    navigate,
  ]);
};

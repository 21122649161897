import { gql } from "@apollo/client";

export const GET_CHECKOUT_SESSION = gql`
  query GetCheckoutSession($sessionId: ID!) {
    checkoutSession: checkoutSessionById(id: $sessionId) {
      id
      allowedPaymentMethodTypes
      items {
        offer {
          id
          modality
          offerPrice
          paymentConfiguration {
            offerBillingPeriod
          }
          purchasingPlan {
            ... on InstallmentPlan {
              price
              billingPeriod
            }
          }
          name
        }
        quantity
        orderItemToReplace {
          offer {
            purchasingPlan {
              ... on InstallmentPlan {
                price
              }
            }
          }
        }
        id
        appliedBenefits {
          id
          type
          scheduledPaymentOffsetHours
          discountAmount
        }
      }
      createdOrder {
        id
        auditMetadata {
          createdAt
        }
        paymentMethod {
          type
        }
      }
      locale
      paymentMethod {
        awsAccountId
      }
      preCheckoutPage {
        cartComponent {
          cancelRedirectConfig {
            displayText
            url
          }
          groups {
            items {
              text
            }
            name
          }
          heading
        }
        finalizeCheckoutSessionComponent {
          submitText
          terms {
            sections {
              inlineLinks {
                displayText
                inlineReplacementKey
                url
              }
              style
              text
            }
          }
        }
        title
      }
      postCheckoutPage {
        confirmationComponent {
          heading
          successRedirectConfig {
            displayText
            url
          }
          textSections {
            text
          }
        }
        orderSummaryComponent {
          items {
            label
            value
          }
          subtext {
            text
          }
        }
        title
      }
      status
      type
      user {
        id
      }
    }
  }
`;

export const APPLY_BENEFIT_TO_OFFER = gql`
  query GetOfferById($id: ID!, $appliedBeneifts: [ID!]) {
    offerById(id: $id, appliedBenefits: $appliedBeneifts) {
      offerPrice
      discountedPrice
      applicableBenefits {
        type
        scheduledPaymentOffsetHours
      }
      paymentConfiguration {
        paymentScheduleConfiguration {
          ... on RelativeScheduleConfiguration {
            offsetInHours
          }
        }
        offerBillingPeriod
      }
      purchasingPlan {
        ... on InstallmentPlan {
          price
          billingPeriod
          scheduledInstallments {
            installmentAttemptDates
          }
        }
      }
    }
  }
`;

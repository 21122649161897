declare global {
  interface Window {
    AWSMA: any;
  }
}

export const createEventTrigger = (eventType: string, eventName: string) => {
  return () => {
    window.AWSMA.ready(() => {
      document.dispatchEvent(
        new CustomEvent(window.AWSMA.TRIGGER_EVENT, {
          detail: {
            event: {
              type: eventType,
              name: eventName,
            },
          },
        })
      );
    });
  };
};

import { ApolloError } from "@apollo/client";
import { USER_HAS_NO_DOCEBO_ID_ERROR } from "./constants";

/*
  This function performs left-to-right functional-component composition. It allows HOCs to
  be composed declaratively. Similar to Ramda's pipe function: https://ramdajs.com/0.19.1/docs/#pipe
  
  Taken from AwsTcPlatformFrontend. Determined not worth it to extract to a common package now, 
  as the MFE/SBXB project is currently being set up for common FE packages, and wanting to avoid 
  duplicate/throwaway work
 */
export const pipeHOCs =
  (...fn: any[]) =>
  (initialProps: (props: any) => JSX.Element) =>
    fn.reduce(
      (composedHOC, currentHOC) => currentHOC(composedHOC),
      initialProps
    );

/**
 * Ignore the "No current user" network error that happens when the page is
 * reloaded or loaded for the first time. This helps us ignore the transient
 * error state of GraphQL's useQuery hook when it's paired with Amplify.
 *
 * @param error
 * @returns True when there is an error that we want to show the user
 */
export const showError = (error: ApolloError | undefined) => {
  // eslint-disable-next-line prettier/prettier
  return (
    error &&
    (error.graphQLErrors?.length ||
      error.clientErrors.length ||
      !(
        typeof error.networkError === "string" &&
        /No current user/.test(error.networkError)
      ))
  );
};

/**
 * Non-Amazonian B2I User Validation
 * @param audiencePath User audience path
 * @returns boolean indicating B2I status
 */
export const isB2ICustomer = (audiencePath?: string): boolean => {
  return (
    audiencePath === "LoginWithAmazon" ||
    audiencePath === "AWSBuilderID" ||
    audiencePath === "AWSPartnerNetwork"
  );
};

/**
 * User already subscribed validation
 * @param isUserAllowedToCheckout boolean indicating if user can checkout
 * @returns boolean indicating already subscribed status
 */
export const isAlreadySubscribed = (
  isUserAllowedToCheckout?: boolean
): boolean => {
  return isUserAllowedToCheckout !== undefined && !isUserAllowedToCheckout;
};

/**
 * Navigates to external url
 * @param url external url
 */
export const navigateToExternalUrl = (url: string) => {
  window.location.replace(url);
};

export const isNoAssociatedDocebeUserError = (
  error: ApolloError | undefined
): boolean => {
  return error && error.message === USER_HAS_NO_DOCEBO_ID_ERROR ? true : false;
};

/**
 * Formats currency so it will return a decimal only if there is a cent value
 * @param amount dollar value
 * @returns a string with the currency value, will return 0 if input is null
 */
export const formatCurrency = (amount: any): string => {
  const returnAmount = amount ?? 0;
  if (returnAmount % 1 === 0) {
    return returnAmount.toString();
  } else {
    return returnAmount.toFixed(2);
  }
};

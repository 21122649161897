import { useEffect } from "react";
import { GET_CHECKOUT_SESSION } from "../common/api/queries";
import { useQuery } from "@apollo/client";
import { useOnTempCredsUpdated } from "./useOnTempCredsUpdated";
import { useTranslation } from "react-i18next";
import { useBenefitsValidation } from "./useBenefitsValidation";

const useGetCheckoutSessionQuery = (sessionId?: string) =>
  useQuery(GET_CHECKOUT_SESSION, {
    variables: { sessionId },
    skip: sessionId === undefined,
    // used when we 'refetch' session after a mutation (e.g., finalize)
    notifyOnNetworkStatusChange: true,
  });

export const useCheckoutSessionDetails = ({
  sessionId,
}: {
  sessionId?: string;
}) => {
  const {
    data,
    error: checkoutSessionError,
    loading: checkoutSessionLoading,
    networkStatus: checkoutSessionNetworkStatus,
    refetch: refetchCheckoutSession,
  } = useGetCheckoutSessionQuery(sessionId);
  const checkoutSession = data?.checkoutSession;

  const {
    isFreeTrial,
    isPromotional,
    freeTrialEndsDate,
    freeTrialDuration,
    appliedBenefitIds,
    promotionalDiscount,
    promotionalEndsDate,
  } = useBenefitsValidation(checkoutSession?.items[0]);

  useOnTempCredsUpdated(() => refetchCheckoutSession());
  const { i18n } = useTranslation();

  const locale = checkoutSession?.locale;
  useEffect(() => {
    if (locale) {
      i18n.changeLanguage(locale);
    }
  }, [locale]);

  return {
    checkoutSession,
    checkoutSessionLoading,
    checkoutSessionError,
    checkoutSessionNetworkStatus,
    isFreeTrial,
    isPromotional,
    freeTrialEndsDate,
    freeTrialDuration,
    appliedBenefitIds,
    promotionalDiscount,
    promotionalEndsDate,
  };
};

import CheckoutSessionMain from "../pages/CheckoutSessionMain";
import PageNotFound from "../pages/PageNotFound";
import AccessDenied from "../pages/AccessDenied";
import HandleAwsSignIn from "../pages/HandleAwsSignIn";
import LoadingContent from "../components/LoadingContent";
import MiddleManPage from "../pages/MiddleManPage";
import AlreadySubscribed from "../pages/AlreadySubscribed";

export const ROUTE_MIDDLE_MAN_PAGE = "/skillbuilder/:offerBillingPeriod";
export const ROUTE_CHECKOUT_SESSION = "/sessions/:sessionId";
export const ROUTE_ACCESS_DENIED = "/accessdenied";
export const ROUTE_HANDLE_AWS_SIGN_IN = "/aws-signin";
export const ROUTE_PAGE_NOT_FOUND = "*";
export const ROUTE_ALREADY_SUBSCRIBED = "/already-subscribed";

interface CheckoutRoute {
  path: string;
  Component: (props: any) => JSX.Element;
}

export const getCheckoutRoutes = (): CheckoutRoute[] => {
  return [
    {
      path: "/",
      // Show loading when redirecting back from gandalf auth
      Component: LoadingContent,
    },
    { path: ROUTE_MIDDLE_MAN_PAGE, Component: MiddleManPage },
    {
      path: ROUTE_CHECKOUT_SESSION,
      Component: CheckoutSessionMain,
    },
    {
      path: ROUTE_ACCESS_DENIED,
      Component: AccessDenied,
    },
    {
      path: ROUTE_HANDLE_AWS_SIGN_IN,
      Component: HandleAwsSignIn,
    },
    {
      path: ROUTE_PAGE_NOT_FOUND,
      Component: PageNotFound,
    },
    {
      path: ROUTE_ALREADY_SUBSCRIBED,
      Component: AlreadySubscribed,
    },
  ];
};

import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import Box from "@amzn/awsui-components-react/polaris/box";
import styled from "styled-components";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import { FRONTEND_SELECTORS } from "../common/constants";
import { useTranslation } from "react-i18next";

const LoadingBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

type LoadingPageProps = {
  text?: string;
};

const LoadingContent = (props: LoadingPageProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <AppLayout
      content={
        <ContentLayout>
          <LoadingBox data-testid="Loading_Box" margin="n" fontWeight="light">
            <SpaceBetween size="xs" direction="horizontal">
              <Spinner variant="disabled" data-testid="Loading_Spinner" />
              <Box fontSize="heading-s" color="text-body-secondary">
                {props.text ?? t("loadingPage_loadingText_loading")}
              </Box>
            </SpaceBetween>
          </LoadingBox>
        </ContentLayout>
      }
      navigationHide
      toolsHide
      disableContentPaddings
      headerSelector={FRONTEND_SELECTORS.HEADER}
      footerSelector={FRONTEND_SELECTORS.FOOTER}
    />
  );
};

export default LoadingContent;

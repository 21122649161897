import { CheckoutSessionItem, BenefitType } from "../common/types";

export const useBenefitsValidation = (
  checkoutSessionItem?: CheckoutSessionItem
): {
  isFreeTrial: boolean;
  isPromotional: boolean;
  freeTrialEndsDate: string | undefined;
  freeTrialDuration: number | undefined;
  appliedBenefitIds: string[] | undefined;
  promotionalDiscount: number | undefined;
  promotionalEndsDate: string | undefined;
} => {
  let isFreeTrial = false;
  let isPromotional = false;
  let promotionalDiscount;
  let freeTrialEndsDate;
  let promotionalEndsDate;
  let promotionalEndsDateString;
  let freeTrialDuration;
  let freeTrialEndDateMilliSecs;
  const appliedBenefitIds: string[] = [];
  const dateTimeFormatter = new Intl.DateTimeFormat(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  if (checkoutSessionItem) {
    const appliedBenefits = checkoutSessionItem.appliedBenefits;
    if (appliedBenefits) {
      for (const appliedBenefit of appliedBenefits) {
        appliedBenefitIds.push(appliedBenefit.id);
        if (appliedBenefit.type === BenefitType.FREETRIAL) {
          isFreeTrial = true;
          freeTrialEndDateMilliSecs =
            new Date().getTime() +
            appliedBenefit.scheduledPaymentOffsetHours * 60 * 60 * 1000;
          freeTrialEndsDate = dateTimeFormatter.format(
            new Date(freeTrialEndDateMilliSecs)
          );
          freeTrialDuration = appliedBenefit.scheduledPaymentOffsetHours / 24;
        } else if (appliedBenefit.type === BenefitType.DISCOUNT) {
          isPromotional = true;
          promotionalDiscount = appliedBenefit.discountAmount;
        }
      }
    }

    if (isPromotional) {
      promotionalEndsDate = isFreeTrial
        ? new Date(freeTrialEndDateMilliSecs)
        : new Date();
      promotionalEndsDate.setFullYear(promotionalEndsDate.getFullYear() + 1);
      promotionalEndsDateString = dateTimeFormatter.format(promotionalEndsDate);
    }
  }

  return {
    isFreeTrial: isFreeTrial,
    isPromotional: isPromotional,
    freeTrialEndsDate: freeTrialEndsDate,
    freeTrialDuration: freeTrialDuration,
    appliedBenefitIds: appliedBenefitIds,
    promotionalDiscount: promotionalDiscount,
    promotionalEndsDate: promotionalEndsDateString,
  };
};

type Maybe<T> = T | null;

export enum PaymentMethodType {
  AWS_ACCOUNT = "AWS_ACCOUNT",
}

export enum CheckoutSessionType {
  NEW_ORDER = "NEW_ORDER",
  REPLACE_ORDER_ITEM = "REPLACE_ORDER_ITEM",
}

export enum CheckoutSessionStatus {
  COMPLETE = "COMPLETE",
  EXPIRED = "EXPIRED",
  OPEN = "OPEN",
  PENDING_COMPLETION = "PENDING_COMPLETION",
}

export enum OfferStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum OfferModality {
  PADAWAN = "PADAWAN",
  AWS_JAM = "AWS_JAM",
  DIGITAL = "DIGITAL",
}

export type Installment = {
  __typename?: "Installment";
  installmentAttemptDates: string[];
};

export enum OfferBillingPeriod {
  MONTHLY_LEASE = "MONTHLY_LEASE",
  MONTHLY = "MONTHLY",
  ANNUAL = "ANNUAL",
  ONE_TIME = "ONE_TIME",
}

export enum HandleOpenClientSessionStrategy {
  ALLOW_SINGLE = "ALLOW_SINGLE",
  ALLOW_MULTIPLE = "ALLOW_MULTIPLE",
  REPLACE_EXISTING = "REPLACE_EXISTING",
}

export type InstallmentPlan = {
  __typename?: "InstallmentPlan";
  price: number;
  billingPeriod: OfferBillingPeriod;
  scheduledInstallments: Installment[];
};

export type PurchasingPlan = InstallmentPlan;

export type ScheduleConfiguration = {
  offsetInHours: number;
};

export type PaymentConfiguration = {
  __typename?: "PaymentConfiguration";
  offerBillingPeriod: OfferBillingPeriod;
  paymentScheduleConfiguration: ScheduleConfiguration;
};

export type Offer = {
  __typename?: "Offer";
  id: string;
  offerPrice: number;
  modality?: string;
  purchasingPlan?: Maybe<PurchasingPlan>;
  paymentConfiguration: Maybe<PaymentConfiguration>;
  name: string;
};

export enum BenefitType {
  FREETRIAL = "FREETRIAL",
  DISCOUNT = "DISCOUNT",
}

export type OfferBenefit = {
  __typename?: "Offer";
  id: string;
  type: BenefitType;
  scheduledPaymentOffsetHours: number;
  discountAmount: number;
};

export type OrderItem = {
  __typename?: "OrderItem";
  offer: Offer;
};

export type CheckoutSessionItem = {
  __typename?: "CheckoutSessionItem";
  id: string;
  offer?: Maybe<Offer>;
  orderItemToReplace?: Maybe<OrderItem>;
  quantity?: Maybe<number>;
  appliedBenefits?: Maybe<OfferBenefit[]>;
};

export enum TextStyle {
  BOLD = "BOLD",
  NORMAL = "NORMAL",
}

export type LinkConfig = {
  __typename?: "LinkConfig";
  displayText: string;
  inlineReplacementKey?: Maybe<string>;
  url: string;
};

export type TextSection = {
  __typename?: "TextSection";
  inlineLinks: LinkConfig[];
  style: TextStyle;
  text: string;
};

export type CartGroup = {
  __typename?: "CartGroup";
  items: TextSection[];
  name: string;
};

export type CartComponent = {
  __typename?: "CartComponent";
  cancelRedirectConfig: LinkConfig;
  groups: CartGroup[];
  heading: string;
};

export type CheckoutSessionTerm = {
  __typename?: "CheckoutSessionTerm";
  sections: TextSection[];
};

export type ConfirmationComponent = {
  __typename?: "ConfirmationComponent";
  heading: string;
  successRedirectConfig?: LinkConfig;
  textSections: TextSection[];
};

export type FinalizeCheckoutSessionComponent = {
  __typename?: "FinalizeCheckoutSessionComponent";
  submitText: string;
  terms: CheckoutSessionTerm[];
};

export type PreCheckoutPage = {
  __typename?: "PreCheckoutPage";
  cartComponent: CartComponent;
  finalizeCheckoutSessionComponent: FinalizeCheckoutSessionComponent;
  title: string;
};

export type OrderSummaryItem = {
  __typename?: "OrderSummaryItem";
  label: string;
  value: string;
};

export type OrderSummaryComponent = {
  __typename?: "OrderSummaryComponent";
  items: OrderSummaryItem[];
  subtext: TextSection;
};

export type PostCheckoutPage = {
  __typename?: "PostCheckoutPage";
  confirmationComponent: ConfirmationComponent;
  orderSummaryComponent: OrderSummaryComponent;
  title: string;
};

export type PaymentMethod = {
  __typename?: "PaymentMethod";
  id: string;
  awsAccountId: string;
  type: PaymentMethodType;
};

export type User = {
  __typename?: "User";
  id: string;
};

export type AuditMetadata = {
  __typename?: "AuditMetadata";
  createdAt: string;
};

export type Order = {
  __typename?: "Order";
  id: string;
  paymentMethod: PaymentMethod;
  auditMetadata: AuditMetadata;
};

export type CheckoutSession = {
  __typename?: "CheckoutSession";
  allowedPaymentMethodTypes?: Maybe<PaymentMethodType[]>;
  id: string;
  items: CheckoutSessionItem[];
  locale: string;
  paymentMethod?: Maybe<PaymentMethod>;
  preCheckoutPage: PreCheckoutPage;
  postCheckoutPage: PostCheckoutPage;
  status: CheckoutSessionStatus;
  type: CheckoutSessionType;
  user: User;
  createdOrder?: Order;
};

export type LinkConfigInput = {
  url: string;
  displayText: string;
  inlineReplacementKey: Maybe<string>;
};

export type TextSectionInput = {
  text: string;
  style: TextStyle;
  inlineLinks: Maybe<LinkConfigInput[]>;
};

export type CartGroupInput = {
  name: string;
  items: TextSectionInput[];
};

export type CartComponentInput = {
  heading: string;
  groups: CartGroupInput[];
  cancelRedirectConfig: LinkConfigInput;
};

export type CheckoutSessionTermInput = {
  sections: TextSectionInput[];
};

export type FinalizeCheckoutSessionComponentInput = {
  terms: CheckoutSessionTermInput[];
  submitText: string;
};

export type PreCheckoutPageInput = {
  title: string;
  cartComponent: CartComponentInput;
  finalizeCheckoutSessionComponent: FinalizeCheckoutSessionComponentInput;
};

export type ConfirmationComponentInput = {
  heading: string;
  textSections: TextSectionInput[];
  successRedirectConfig: Maybe<LinkConfigInput>;
};

export type OrderSummaryItemInput = {
  label: string;
  value: string;
};

export type OrderSummaryComponentInput = {
  items: OrderSummaryItemInput[];
  subtext: TextSectionInput;
};

export type PostCheckoutPageInput = {
  title: string;
  confirmationComponent: ConfirmationComponentInput;
  orderSummaryComponent: OrderSummaryComponentInput;
};

export type CreateNewSbDigitalOrderCheckoutSessionInput = {
  offerId: string;
  locale: string;
};

import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Auth } from "@aws-amplify/auth";
import {
  NodeEnvironment,
  getNodeEnvironment,
} from "../../common/nodeEnvironment";

const endpoints: Record<string, string> = {
  [NodeEnvironment.DEVELOPMENT]:
    "https://beta.api.onboard.skillbuilder.training.aws.dev/graphql",
  [NodeEnvironment.BETA]:
    "https://beta.api.onboard.skillbuilder.training.aws.dev/graphql",
  [NodeEnvironment.GAMMA]:
    "https://gamma.api.onboard.skillbuilder.training.aws.dev/graphql",
  [NodeEnvironment.PROD]: "https://api.onboard.skillbuilder.aws/graphql",
};

const getClientEndpoint = () =>
  endpoints[getNodeEnvironment()] || endpoints[NodeEnvironment.DEVELOPMENT];

const httpLink = createHttpLink({
  uri: getClientEndpoint(),
  preserveHeaderCase: true,
});

const authLink = setContext((_, { headers }) => {
  return Auth.currentSession().then((res) => {
    const idToken = res.getIdToken();
    const jwt = idToken.getJwtToken();

    return {
      headers: {
        ...headers,
        Authorization: jwt ? `Bearer ${jwt}` : "",
      },
    };
  });
});

export const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache(),
});

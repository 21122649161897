import { APPLICATION_URL } from "../common/constants";
import { NodeEnvironment, getNodeEnvironment } from "../common/nodeEnvironment";

/*
  Borrowed from AwsTcPlatformFrontend (only the DEVELOPMENT environment config), 
  at least until the platform Auth Gateway is set up which will provide a
  common auth solution using cookies
 */

interface OAthConfig {
  domain: string;
  scope: string[];
  responseType: string;
  redirectSignIn: string;
  redirectSignOut: string;
  signInUIDomain: string;
}

export interface AuthConfiguration {
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  oauth: Partial<OAthConfig>;
}

const authCommonConfiguration: Partial<AuthConfiguration> = {
  region: "us-east-1",
  userPoolId: "us-east-1_KcXGNtlRY",
  oauth: {
    domain: "gandalf-prod.auth.us-east-1.amazoncognito.com",
    scope: ["openid"],
    responseType: "code",
    signInUIDomain: "login.us-east-1.auth.skillbuilder.aws",
  },
};

// can only authenticate in DEVELOPMENT right now until we are onboarded to gandalf
const authVariableConfiguration: Record<string, Partial<AuthConfiguration>> = {
  [NodeEnvironment.DEVELOPMENT]: {
    userPoolWebClientId: "1kgqrlr3t9osbcb2o5dagmptrs",
    oauth: {
      redirectSignIn: APPLICATION_URL[NodeEnvironment.DEVELOPMENT],
      redirectSignOut: APPLICATION_URL[NodeEnvironment.DEVELOPMENT],
    },
  },
  [NodeEnvironment.BETA]: {
    userPoolWebClientId: "1kgqrlr3t9osbcb2o5dagmptrs",
    oauth: {
      redirectSignIn: APPLICATION_URL[NodeEnvironment.BETA],
      redirectSignOut: APPLICATION_URL[NodeEnvironment.BETA],
    },
  },
  [NodeEnvironment.GAMMA]: {
    userPoolWebClientId: "57a31suc84a3sjissii5dgjpkf",
    oauth: {
      redirectSignIn: APPLICATION_URL[NodeEnvironment.GAMMA],
      redirectSignOut: APPLICATION_URL[NodeEnvironment.GAMMA],
    },
  },
  [NodeEnvironment.PROD]: {
    userPoolWebClientId: "79ld2q6pid4ca5nuo6nrv4m2f5",
    oauth: {
      redirectSignIn: APPLICATION_URL[NodeEnvironment.PROD],
      redirectSignOut: APPLICATION_URL[NodeEnvironment.PROD],
    },
  },
};

const dynamicConfiguration = authVariableConfiguration[getNodeEnvironment()];

const oauthConfig = {
  ...authCommonConfiguration.oauth,
  ...dynamicConfiguration?.oauth,
};

export const authConfiguration = {
  ...authCommonConfiguration,
  ...dynamicConfiguration,
  oauth: oauthConfig,
};

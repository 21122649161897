import { Fragment } from "react";
import Markdown from "markdown-to-jsx";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import Link from "@amzn/awsui-components-react/polaris/link";
import { CheckoutSession } from "../common/types";
import Box from "@amzn/awsui-components-react/polaris/box";
import { createCheckoutEventTrigger } from "../analytics/createCheckoutEventTrigger";

interface CartProps {
  checkoutSession: CheckoutSession;
}

const Cart = ({ checkoutSession }: CartProps): JSX.Element => {
  const {
    preCheckoutPage: { cartComponent },
  } = checkoutSession;

  return (
    <Container data-testid="cart">
      <SpaceBetween size="xxxs">
        <Header variant="h2">{cartComponent.heading}</Header>
        <SpaceBetween size="xs">
          {cartComponent.groups.map((group, groupIdx) => (
            <Fragment key={groupIdx}>
              {group.name === "" ? (
                <></>
              ) : (
                <Box
                  data-testid="preCheckout_cart_group_name"
                  fontSize="heading-m"
                  padding={{ vertical: "xxs" }}
                >
                  {group.name}
                </Box>
              )}
              <Box
                data-testid="preCheckout_cart_group_items"
                color="text-body-secondary"
              >
                {group.items.map((item, itemIdx) => (
                  <Fragment key={itemIdx}>
                    {itemIdx > 0 && <br />}
                    <Markdown>{item.text}</Markdown>
                  </Fragment>
                ))}
              </Box>
            </Fragment>
          ))}
          <Link
            data-testid="cancel_redirect_link"
            href={cartComponent.cancelRedirectConfig.url}
            onFollow={() => {
              const triggerCheckoutEvent = createCheckoutEventTrigger(
                checkoutSession,
                "click",
                "Change_Event",
                "Event_checkout"
              );
              if (typeof triggerCheckoutEvent === "function")
                triggerCheckoutEvent();
            }}
          >
            {cartComponent.cancelRedirectConfig.displayText}
          </Link>
        </SpaceBetween>
      </SpaceBetween>
    </Container>
  );
};

export default Cart;

import { useTranslation } from "react-i18next";
import LoadingContent from "../components/LoadingContent";
import { useMiddleManContent } from "../hooks/useMiddleManContent";
import ErrorContent from "./ErrorContent";
import { useEffect } from "react";
import {
  isAlreadySubscribed,
  isB2ICustomer,
  showError,
  navigateToExternalUrl,
  isNoAssociatedDocebeUserError,
} from "../common/utilityFunctions";
import Box from "@amzn/awsui-components-react/polaris/box";
import AlreadySubscribed from "../pages/AlreadySubscribed";
import { useSearchParams, useNavigate } from "react-router-dom";
import { BILLING_DOMAIN, SUBSCRIPTIONS } from "../common/constants";
import { getNodeEnvironment } from "../common/nodeEnvironment";

interface MiddleManContentContentProps {
  offerBillingPeriod?: string;
  isLoggedIn?: boolean;
  audiencePath?: string;
  locale?: string;
  userVerified?: boolean;
  preventAuthRedirect?: boolean;
}

const MiddleManContent = ({
  offerBillingPeriod,
  isLoggedIn,
  audiencePath,
  locale,
  userVerified,
  preventAuthRedirect = false,
}: MiddleManContentContentProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    isUserAllowedToCheckout,
    createSBDigitalCheckoutSessionError,
    createSBDigitalCheckoutSessionLoading,
    checkoutSessionRedirectUrl,
    isUnSupportedAudiencePath,
  } = useMiddleManContent(
    isLoggedIn,
    audiencePath,
    offerBillingPeriod,
    locale,
    userVerified
  );

  const isLoading = !isLoggedIn;

  // When CheckoutSession has been created successfully
  useEffect(() => {
    if (
      !isLoading &&
      !createSBDigitalCheckoutSessionLoading &&
      checkoutSessionRedirectUrl
    ) {
      const urlPath = new URL(checkoutSessionRedirectUrl).pathname;
      const redirectUrlPath = preventAuthRedirect
        ? `${urlPath}?preventAuthRedirect=true`
        : urlPath;

      if (searchParams.size > 0) {
        let parameters = "";
        for (const [key, value] of searchParams.entries()) {
          parameters += `&${key}=${value}`;
        }
        parameters = preventAuthRedirect
          ? parameters
          : "?" + parameters.substring(1);
        navigate(redirectUrlPath + parameters);
      } else {
        navigate(redirectUrlPath);
      }
    }
  }, [checkoutSessionRedirectUrl, preventAuthRedirect, navigate]);

  // When there exists error in requests
  if (
    showError(createSBDigitalCheckoutSessionError) ||
    isUnSupportedAudiencePath
  ) {
    return (
      <ErrorContent
        errorType={
          isNoAssociatedDocebeUserError(createSBDigitalCheckoutSessionError)
            ? "noAssociatedDoceboUser"
            : "badRequest"
        }
      />
    );
  }

  if (audiencePath === "AmazonFederate") {
    return <AlreadySubscribed />;
  }

  if (
    !isLoading &&
    !createSBDigitalCheckoutSessionLoading &&
    isB2ICustomer(audiencePath) &&
    isAlreadySubscribed(isUserAllowedToCheckout)
  ) {
    navigateToExternalUrl(BILLING_DOMAIN[getNodeEnvironment()] + SUBSCRIPTIONS);
  }

  return (
    <Box>
      <LoadingContent text={t("loadingPage_loadingText_loading")} />
    </Box>
  );
};

export default MiddleManContent;

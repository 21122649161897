import { Auth } from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";

/*
  Borrowed from AwsTcPlatformFrontend, at least until
  the platform Auth Gateway is set up which will provide a
  common auth solution using cookies
 */
export const useAmplifySubscription = () => {
  const [signedInUser, setSignedInUser] = useState(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          Auth.currentAuthenticatedUser().then(setSignedInUser);
          break;
        case "customOAuthState": {
          const { pathname, search } = JSON.parse(data);
          navigate(
            { pathname, search },
            { state: { redirectFromAmplifyAuth: true } }
          );
          break;
        }
        case "signOut":
          setSignedInUser(undefined);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          // Sign-in failure will be handled at some point in the future.
          break;
      }
    });
  }, []);

  return signedInUser;
};

import Header from "@amzn/awsui-components-react/polaris/header";
import { CheckoutSession } from "../common/types";
import Box from "@amzn/awsui-components-react/polaris/box";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Cart from "./Cart";
import PaymentMethod from "./PaymentMethod";
import AwsAccountModal from "./AwsAccountModal";
import FinalizeCheckoutSession from "./FinalizeCheckoutSession";
import LoadingContent from "./LoadingContent";
import { FlashbarError } from "../pages/CheckoutSessionMain";
import { usePreCheckoutDetails } from "../hooks/usePreCheckoutDetails";
import { createCheckoutEventTrigger } from "../analytics/createCheckoutEventTrigger";
import { useEffect } from "react";
import {
  FlagNames,
  isFeatureEnabled,
} from "@amzn/aws-tc-commerce-feature-flags";
import {
  getNodeEnvironment,
  NODE_ENV_STAGE_MAPPING,
} from "../common/nodeEnvironment";

interface PreCheckoutDetailsProps {
  checkoutSession: CheckoutSession;
  isFreeTrial: boolean;
  isPromotional: boolean;
  appliedBenefitIds: string[] | undefined;
  freeTrialEndsDate: string | undefined;
  freeTrialDuration: number | undefined;
  promotionalDiscount: number | undefined;
  promotionalEndsDate: string | undefined;
  setFlashbarError: (flashbarError?: FlashbarError) => void;
}

const PreCheckoutDetails = ({
  checkoutSession,
  isFreeTrial,
  isPromotional,
  appliedBenefitIds,
  freeTrialEndsDate,
  freeTrialDuration,
  promotionalDiscount,
  promotionalEndsDate,
  setFlashbarError,
}: PreCheckoutDetailsProps): JSX.Element => {
  const {
    awsAccountModalVisible,
    setAwsAccountModalVisible,
    shouldRenderPaymentMethod,
    shouldRenderAwsAccountModal,
    finalizeSession,
    finalizeSessionLoading,
    isApplicableForFreeTrial,
  } = usePreCheckoutDetails(checkoutSession, isFreeTrial, setFlashbarError);

  useEffect(() => {
    // Dismiss flashbar errors on component dismount
    return () => setFlashbarError(undefined);
  }, []);

  useEffect(() => {
    if (
      !isApplicableForFreeTrial &&
      isFeatureEnabled(
        FlagNames.SKILL_BUILDER_CHECKOUT_FREE_TRIAL,
        NODE_ENV_STAGE_MAPPING[getNodeEnvironment()]
      )
    ) {
      setFlashbarError(FlashbarError.NOT_APPLICABLE_FOR_FREE_TRIAL);
    }
  }, [isApplicableForFreeTrial, setFlashbarError]);

  if (finalizeSessionLoading) {
    return <LoadingContent />;
  }

  const handleContinueToAwsClick = () => {
    setAwsAccountModalVisible(true);
    const triggerCheckoutEvent = createCheckoutEventTrigger(
      checkoutSession,
      "click",
      "Add_payment",
      "Event_checkout"
    );
    if (typeof triggerCheckoutEvent === "function") triggerCheckoutEvent();
  };

  return (
    <Box data-testid="pre_checkout_details">
      {shouldRenderAwsAccountModal && (
        <AwsAccountModal
          visible={awsAccountModalVisible}
          onDismiss={() => setAwsAccountModalVisible(false)}
          checkoutSession={checkoutSession}
        />
      )}
      <SpaceBetween size="l">
        <Header variant="h1">{checkoutSession.preCheckoutPage.title}</Header>
        <Grid
          gridDefinition={[
            {
              colspan: { l: 8, s: 8, xxs: 12 },
            },
            {
              colspan: { l: 4, s: 4, xxs: 12 },
            },
          ]}
        >
          <SpaceBetween size="l">
            <Cart checkoutSession={checkoutSession} />
            {shouldRenderPaymentMethod && (
              <PaymentMethod
                checkoutSession={checkoutSession}
                onClickContinueToAws={handleContinueToAwsClick}
              />
            )}
          </SpaceBetween>
          <FinalizeCheckoutSession
            checkoutSession={checkoutSession}
            isFreeTrial={isFreeTrial}
            isPromotional={isPromotional}
            appliedBenefitIds={appliedBenefitIds}
            freeTrialEndsDate={freeTrialEndsDate}
            freeTrialDuration={freeTrialDuration}
            promotionalDiscount={promotionalDiscount}
            promotionalEndsDate={promotionalEndsDate}
            finalizeSession={() => finalizeSession()}
            onClickContinueToAws={handleContinueToAwsClick}
          />
        </Grid>
      </SpaceBetween>
    </Box>
  );
};

export default PreCheckoutDetails;

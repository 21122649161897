// Promise polyfill
import "core-js/features/promise";
import "@amzn/awsui-global-styles/polaris.css";
import { createRoot } from "react-dom/client";
import App from "./App";
import {
  TEMP_CREDS_SET_EVENT,
  E2E_TEST_ENV_KEY,
  FRONTEND_SELECTORS,
} from "./common/constants";
import { ApolloProvider } from "@apollo/client";
import { client } from "./common/api/client";
import { BrowserRouter } from "react-router-dom";
import { MemoryStorage } from "@aws-amplify/core";

declare global {
  interface Window {
    loadTemporaryCredentials: (credentials: string) => void;
  }
}

document.addEventListener("DOMContentLoaded", () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  createRoot(document.querySelector(FRONTEND_SELECTORS.APP)!).render(
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  );
});

type testCredentials = {
  lastAuthKey: string;
  lastAuthValue: string;
  accessTokenKey: string;
  accessTokenValue: string;
  idTokenKey: string;
  idTokenValue: string;
};

window.loadTemporaryCredentials = (stringifiedCredentials: string) => {
  const credentials = JSON.parse(stringifiedCredentials) as testCredentials;
  MemoryStorage.setItem(
    credentials.accessTokenKey,
    credentials.accessTokenValue
  );
  MemoryStorage.setItem(credentials.idTokenKey, credentials.idTokenValue);
  MemoryStorage.setItem(credentials.lastAuthKey, credentials.lastAuthValue);
  MemoryStorage.setItem(E2E_TEST_ENV_KEY, "true");
  window.dispatchEvent(new Event(TEMP_CREDS_SET_EVENT));
};

import { useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Box from "@amzn/awsui-components-react/polaris/box";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import { FRONTEND_SELECTORS } from "../common/constants";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import { usePreventAuthRedirectParam } from "../hooks/usePreventAuthRedirectParam";
import MiddleManContent from "../components/MiddleManContent";
import { useOnTempCredsUpdated } from "../hooks/useOnTempCredsUpdated";

const MiddleManPage = (): JSX.Element => {
  const preventAuthRedirectParam = usePreventAuthRedirectParam();
  const { applicationUser, refetchAuth } = useAuth(preventAuthRedirectParam);
  const { offerBillingPeriod } = useParams();

  useOnTempCredsUpdated(() => refetchAuth());

  return (
    <AppLayout
      content={
        <ContentLayout>
          <Box padding="xxxl">
            <MiddleManContent
              offerBillingPeriod={offerBillingPeriod}
              isLoggedIn={applicationUser?.isLoggedIn}
              audiencePath={applicationUser?.audiencePath}
              locale={applicationUser?.locale}
              userVerified={applicationUser?.userVerified}
              preventAuthRedirect={preventAuthRedirectParam}
            />
          </Box>
        </ContentLayout>
      }
      navigationHide
      toolsHide
      disableContentPaddings
      headerSelector={FRONTEND_SELECTORS.HEADER}
      footerSelector={FRONTEND_SELECTORS.FOOTER}
    />
  );
};

export default MiddleManPage;

import { Stages } from "@amzn/aws-tc-commerce-feature-flags";

export enum NodeEnvironment {
  TEST = "test",
  DEVELOPMENT = "development",
  BETA = "beta",
  GAMMA = "gamma",
  PROD = "prod",
}

export const getNodeEnvironment = (
  environmentValue: string = process.env.NODE_ENV || ""
): NodeEnvironment => {
  const environment = Object.keys(NodeEnvironment).filter(
    (key) => key === environmentValue.toUpperCase()
  )[0];

  if (!environment) {
    throw new Error(`Node environment [${environmentValue}] is not valid`);
  }
  return NodeEnvironment[environment as keyof typeof NodeEnvironment];
};

export const NODE_ENV_STAGE_MAPPING: Record<NodeEnvironment, Stages> = {
  [NodeEnvironment.TEST]: "Dev",
  [NodeEnvironment.DEVELOPMENT]: "Dev",
  [NodeEnvironment.BETA]: "Beta",
  [NodeEnvironment.GAMMA]: "Gamma",
  [NodeEnvironment.PROD]: "Prod",
};

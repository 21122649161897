import { CheckoutSession, CheckoutSessionType } from "./../common/types";
import { formatCurrency } from "../common/utilityFunctions";
import { useQuery } from "@apollo/client";
import { APPLY_BENEFIT_TO_OFFER } from "../common/api/queries";

const DISPLAY_KEY_BY_BILLING_PERIOD: Record<string, string> = {
  MONTHLY: "preCheckout_finalizeCheckout_estimatedTotal_value_period_month",
  MONTHLY_LEASE:
    "preCheckout_finalizeCheckout_estimatedTotal_value_period_month",
  ANNUAL: "preCheckout_finalizeCheckout_estimatedTotal_value_period_year",
  ONE_TIME: "preCheckout_finalizeCheckout_estimatedTotal_value_period_oneTime",
};

const useApplyBenefitToOfferQuery = (
  offerId: string,
  appliedBenefitIds?: string[]
) =>
  useQuery(APPLY_BENEFIT_TO_OFFER, {
    variables: { id: offerId, appliedBeneifts: appliedBenefitIds },
    skip: offerId === undefined,
  });

export const useFinalizeCheckoutSession = (
  checkoutSession: CheckoutSession,
  appliedBenefitIds?: string[],
  isPromotional?: boolean
) => {
  const shouldShowContinueToAws =
    checkoutSession.type === CheckoutSessionType.NEW_ORDER &&
    !checkoutSession.paymentMethod;

  const {
    preCheckoutPage: { finalizeCheckoutSessionComponent },
  } = checkoutSession;

  if (checkoutSession.items.length !== 1) {
    throw new Error("Can only handle sessions with 1 item currently");
  }

  const item = checkoutSession.items[0];
  const quantity = item.quantity ?? 1;

  const offer = item.offer;

  if (!offer) {
    throw new Error("Session should have offer");
  }

  const { id: offerId } = offer;

  const { data: applyBenefitToOfferData, loading: applyBenefitToOfferLoading } =
    useApplyBenefitToOfferQuery(offerId, appliedBenefitIds);

  let offerPrice;
  let discountedPrice;
  let purchasingPlan;
  let paymentConfiguration;

  if (applyBenefitToOfferData) {
    const applyBenefitToOfferQueryResult = applyBenefitToOfferData.offerById;
    offerPrice = formatCurrency(applyBenefitToOfferQueryResult.offerPrice);
    discountedPrice = formatCurrency(
      applyBenefitToOfferQueryResult.discountedPrice
    );
    purchasingPlan = applyBenefitToOfferQueryResult.purchasingPlan;
    paymentConfiguration = applyBenefitToOfferQueryResult.paymentConfiguration;
  }

  let displayPeriodIntlKey;
  let pricePerUnit = 0;
  if (purchasingPlan) {
    if (purchasingPlan.__typename !== "InstallmentPlan") {
      throw new Error("Only installment plans are supported currently");
    }
    pricePerUnit = purchasingPlan.price;
    displayPeriodIntlKey =
      DISPLAY_KEY_BY_BILLING_PERIOD[purchasingPlan.billingPeriod];
  } else if (paymentConfiguration) {
    pricePerUnit = isPromotional ? discountedPrice : offerPrice;
    displayPeriodIntlKey =
      DISPLAY_KEY_BY_BILLING_PERIOD[paymentConfiguration.offerBillingPeriod];
  }
  const estimatedTotalPeriodAmount = formatCurrency(pricePerUnit * quantity);

  let previousEstimatedTotalPeriodAmount: string | undefined = undefined;

  if (checkoutSession.type === CheckoutSessionType.REPLACE_ORDER_ITEM) {
    if (!item.orderItemToReplace) {
      throw new Error("Order item to replace is required");
    }

    const {
      offer: { purchasingPlan: previousOfferPurchasingPlan },
    } = item.orderItemToReplace;

    if (previousOfferPurchasingPlan) {
      if (previousOfferPurchasingPlan.__typename !== "InstallmentPlan") {
        throw new Error("Only installment plans are supported currently");
      }

      previousEstimatedTotalPeriodAmount = formatCurrency(
        previousOfferPurchasingPlan.price
      );
    }
  }

  const subtotal = formatCurrency(offerPrice * quantity); // Total price for the purchase across all installments

  return {
    shouldShowContinueToAws,
    finalizeCheckoutSessionComponent,
    displayPeriodIntlKey,
    estimatedTotalPeriodAmount,
    previousEstimatedTotalPeriodAmount,
    subtotal,
    pricePerUnit,
    quantity,
    applyBenefitToOfferLoading,
  };
};

import { Route, Routes } from "react-router";
import CheckoutHeader from "./components/CheckoutHeader";
import CheckoutFooter from "./components/footer/CheckoutFooter";
import { getCheckoutRoutes } from "./common/routes";
import { pipeHOCs } from "./common/utilityFunctions";
import WithAmplifyConfiguration from "./components/higherOrderComponents/WithAmplifyConfiguration";
import WithAmplifySubscription from "./components/higherOrderComponents/WithAmplifySubscription";
import CookieConsentManager from "./cookieManagement/CookieConsentManager";
import { useTranslation, I18nextProvider } from "react-i18next";

const App = (): JSX.Element => {
  const { i18n } = useTranslation();
  const cookieConsentManager: CookieConsentManager = new CookieConsentManager();
  cookieConsentManager.checkForCookieConsent();

  return (
    <I18nextProvider i18n={i18n}>
      <CheckoutHeader />
      <Routes>
        {...getCheckoutRoutes().map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Routes>
      <CheckoutFooter />
    </I18nextProvider>
  );
};

const WithAmplifyConfigAndSubscription = pipeHOCs(
  WithAmplifyConfiguration,
  WithAmplifySubscription
);

export default WithAmplifyConfigAndSubscription(App);

import styled from "styled-components";
import { Box, Link } from "@amzn/awsui-components-react";
import { useTranslation, Trans } from "react-i18next";
import { SUBSCRIPTION_PAGE_URL } from "../common/constants";
import { getNodeEnvironment } from "../common/nodeEnvironment";

const ErrorBox = styled(Box)`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

interface ErrorContentProps {
  errorType:
    | "pageNotFound"
    | "accessDenied"
    | "badRequest"
    | "requestTimeout"
    | "noAssociatedDoceboUser";
}

const ErrorContent = ({ errorType }: ErrorContentProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ErrorBox data-testid="error_content">
      <Box textAlign="center">
        <img
          src="/public/error-page.svg"
          alt={t("errorPage_img_alt")}
          data-testid="Error_Logo"
        />
        <Box variant="h1" textAlign="center" color="text-body-secondary">
          {t(
            `errorPage_${errorType}_header`,
            `errorPage_${errorType}_header.value`
          )}
        </Box>
        <Box variant="p">
          {errorType === "noAssociatedDoceboUser" ? (
            <Trans
              i18nKey={`errorPage_${errorType}_message`}
              components={[
                <Link
                  data-testid={`errorPage_${errorType}_link`}
                  key={`errorPage_${errorType}_key`}
                  href={SUBSCRIPTION_PAGE_URL[getNodeEnvironment()]}
                />,
              ]}
            />
          ) : (
            t(
              `errorPage_${errorType}_message`,
              `errorPage_${errorType}_message.value`
            )
          )}
        </Box>
      </Box>
    </ErrorBox>
  );
};

export default ErrorContent;

import { useEffect, useState } from "react";
import { CheckoutSession, CheckoutSessionType } from "../common/types";
import { FlashbarError } from "../pages/CheckoutSessionMain";
import { useMutation } from "@apollo/client";
import { FINALIZE_SESSION } from "../common/api/mutations";

const useFinalizeSessionMutation = (sessionId: string) => {
  const [
    finalizeSession,
    { loading: finalizeSessionLoading, error: finalizeSessionError },
  ] = useMutation(FINALIZE_SESSION, {
    variables: { input: { sessionId } },
    refetchQueries: ["GetCheckoutSession"],
  });

  return {
    finalizeSession,
    finalizeSessionLoading,
    finalizeSessionError,
  };
};

export const usePreCheckoutDetails = (
  checkoutSession: CheckoutSession,
  isFreeTrial: boolean,
  setFlashbarError: (flashbarError?: FlashbarError) => void
) => {
  const [isApplicableForFreeTrial, setIsApplicableForFreeTrial] =
    useState(true);
  const [awsAccountModalVisible, setAwsAccountModalVisible] = useState(false);
  const { finalizeSession, finalizeSessionLoading, finalizeSessionError } =
    useFinalizeSessionMutation(checkoutSession.id);

  const offerModality = checkoutSession.items[0].offer?.modality;

  useEffect(() => {
    if (offerModality === "DIGITAL" && !isFreeTrial) {
      setIsApplicableForFreeTrial(false);
    }
  }, [offerModality, isFreeTrial, setFlashbarError]);

  useEffect(() => {
    if (finalizeSessionError) {
      setFlashbarError(FlashbarError.ERROR_FINALIZING_SESSION);
    }
  }, [finalizeSessionError, setFlashbarError]);

  return {
    awsAccountModalVisible,
    setAwsAccountModalVisible,
    shouldRenderPaymentMethod:
      checkoutSession.type === CheckoutSessionType.NEW_ORDER,
    shouldRenderAwsAccountModal:
      checkoutSession.type === CheckoutSessionType.NEW_ORDER &&
      !checkoutSession.paymentMethod,
    finalizeSession,
    finalizeSessionLoading,
    isApplicableForFreeTrial,
  };
};
